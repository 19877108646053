import React, { useState, useEffect } from "react";

import "./Case.scss";

import { useTranslation } from "react-i18next";
import {
  useGetUserQuery,
  useGetUserItemsQuery,
} from "../../../redux/cases/cases.js";

import { useSelector } from "react-redux";

export const Case = ({
  name,
  price,
  image,
  url,
  color,
  setOpen,
  open,
  setIsSpinning,
  setTranslateX,
  setCaseOpen,
  dataWin,
  findLastIndexWithName,
  multipliedItems,
  itemWidth,
  setLogin,
  isMuted,
  playAudioOpens,
  selectCountCase,
  setSelectCountCase,
  setCaseOpening,
  setWin,
  setWinner,
  stop,
  setFastWin,
  setPayments,
}) => {
  const { t } = useTranslation();

  const countCase = [1, 2, 3, 4, 5, 10];

  const isAuth = document.cookie
    ?.split("; ")
    .find((row) => row?.startsWith("access_token="));

  const [isDisabled, setIsDisabled] = useState(false);
  const [totalPrice, setTotalPrice] = useState(price);

  const { start_price, end_price, page } = useSelector(
    (state) => state.filterCase
  );

  const { refetch: refetchUserItems } = useGetUserItemsQuery({
    start_price,
    end_price,
    page,
  });
  const { data: balance, refetch: refetchUserData } = useGetUserQuery(null);

  const casePrice = parseFloat(price);
  const userBalance = parseFloat(balance?.data?.profile.balance);

  const handleOpenCase = async (event) => {
    const { isSuccess } = await open({ url: url, count: selectCountCase });
    setTranslateX(0);

    if (isSuccess) {
      refetchUserItems({ start_price, end_price, page });
      refetchUserData();
      setIsSpinning(true);
      setOpen(true);
      setIsDisabled(false);
    }

    setCaseOpening(event);
    setIsDisabled(true);

    const timer = setTimeout(() => {
      if (dataWin && dataWin?.data) {
        const openedItemName = dataWin.data.drops.map((item) => item.name)[0];

        const lastItemIndex = findLastIndexWithName(
          multipliedItems[0],
          openedItemName
        );

        const screenCenterOffset = (5 * itemWidth) / 2;
        const cardCenterOffset = itemWidth / 1;

        if (lastItemIndex !== -1) {
          const leftPosition =
            lastItemIndex * itemWidth - (screenCenterOffset - cardCenterOffset);

          const maxTranslate = multipliedItems[0].length * itemWidth;
          setTranslateX(-Math.min(leftPosition, maxTranslate));
        }
      }
      setCaseOpen(true);
    }, 1000);

    return () => clearTimeout(timer);
  };

  const handleOpenCaseFast = async () => {
    const { isSuccess } = await open({ url: url, count: selectCountCase });

    if (isSuccess) {
      refetchUserItems({ start_price, end_price, page });
      refetchUserData();
      setIsDisabled(false);
    }
    setWinner(true);
    setFastWin(true);

    setIsDisabled(true);
  };

  const handleSelectOpensCase = (count) => {
    setSelectCountCase(count);
    setTotalPrice(casePrice * count);
  };

  return (
    <div className="Case">
      <div className="CaseWrapper">
        <div className={`CaseImg ${color}`}>
          <div className={`CaseShadow ${color}`}></div>
          <img src={image} alt="" />
        </div>
        <div className={`BuyCase ${!isAuth && "not-auth"}`}>
          <h2 className="CaseName">{name}</h2>
          <div className={`CasePrice ${color}`}>{totalPrice} ₽</div>

          <div className="TopUpBalance">
            {isAuth ? (
              userBalance >= totalPrice ? (
                <>
                  <button
                    className="TopUpBalanceBtn"
                    onClick={() => handleOpenCase("open")}
                    disabled={isDisabled}
                  >
                    {t("openCase")}
                  </button>
                  <button
                    className="TopUpBalanceBtn"
                    disabled={isDisabled}
                    onClick={handleOpenCaseFast}
                  >
                    {t("fastOpen")}
                  </button>
                </>
              ) : (
                <>
                  <span>{t("insufficientFunds")}</span>
                  <button
                    className="TopUpBalanceBtn"
                    onClick={() => setPayments((props) => !props)}
                  >
                    {t("replenishBalance")}
                  </button>
                </>
              )
            ) : (
              <>
                <span>{t("notAuthorized")}</span>
                <button
                  className="TopUpBalanceBtn"
                  onClick={() => setLogin(true)}
                >
                  {t("authorize")}
                </button>
              </>
            )}
          </div>
          {/* КОЛИЧЕСТВО ОТКРЫТИЙ КЕЙСА */}
          {isAuth && (
            <div className="SelectCountCase">
              <div className="CountCaseBlock">
                <ul className="CountCaseList">
                  {countCase.map((item, index) => (
                    <li
                      className={
                        selectCountCase === item
                          ? "CountCaseItem active"
                          : "CountCaseItem"
                      }
                      key={index}
                      onClick={() => handleSelectOpensCase(item)}
                    >
                      x<span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";

import "./Categories.scss";
import { useDispatch, useSelector } from "react-redux";

import { setSelectedCategory } from "../../redux/slices/categoriesSlice";

export const Categories = ({ categoryRefs, category }) => {
  const [categorySelected, setCategorySelected] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  const dispatch = useDispatch();

  const selectedCategory = useSelector(
    (state) => state.categorySlice.selectedCategory
  );

  const handleSelectCategory = (name) => {
    dispatch(setSelectedCategory(name));
    setCategorySelected((props) => !props);
  };

  useEffect(() => {
    const selectedRef = categoryRefs.current[selectedCategory];
    if (selectedRef && selectedRef.current) {
      selectedRef.current.scrollIntoView({ behavior: "smooth" });
      const offset = selectedRef.current.offsetTop;
      window.scrollTo({ top: offset - 155, behavior: "smooth" });
    }
  }, [categorySelected]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [categorySelected]);

  return (
    <div
      className={`categories-pseudo ${
        isFixed ? "categories-pseudo_active" : ""
      }`}
    >
      <div className={`categories ${isFixed ? "fixed" : ""}`}>
        <div className="categories_wrapper">
          <ul className="categoriesList">
            {category.slice(0, 9).map((item, index) => (
              <li
                className={`item ${
                  selectedCategory === item.category_name ? "active" : ""
                }`}
                key={index}
                onClick={() => handleSelectCategory(item.category_name)}
                ref={categoryRefs.current[item.category_name]}
              >
                {item.category_name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";

import "./SkinsOutput.scss";
import link_img from "../../../img/link_img";

import { useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import {
  useFetchTradeUrlMutation,
  useGetUserQuery,
} from "../../../redux/cases/cases.js";

export const SkinsOutput = ({ cls, start_price, end_price }) => {
  const { t } = useTranslation();

  const [tradeUrlErr, setTradeUrlErr] = useState(true);
  const [tradeMessage, setTradeMessage] = useState("");
  const [isShowPanel, setShowPanel] = useState(() => {
    const storedValue = localStorage.getItem("isShowPanel");
    return storedValue ? JSON.parse(storedValue) : false;
  });

  const [
    addTradeUrl,
    { error: tradeError, isSuccess: tradeSuccess, refetch: refetchTradeUrl },
  ] = useFetchTradeUrlMutation();
  const { data: userData, refetch: refetchUserData } = useGetUserQuery();

  const {
    register: registerTradeUrl,
    handleSubmit: handleSubmitTradeUrl,
    formState: { errors: errorsTradeUrl },
    setValue: setTradeUrlValue,
  } = useForm({
    defaultValues: {
      trade_url: userData?.data?.profile.trade_url
        ? userData?.data?.profile.trade_url
        : "",
    },
    mode: "onSubmit",
  });

  useEffect(() => {
    if (userData && userData.data && userData.data.profile.trade_url) {
      setTradeUrlValue("trade_url", userData.data.profile.trade_url);
      setTradeUrlErr(false);
    }
  }, [userData]);

  // console.log(tradeSuccess);

  useEffect(() => {
    if (tradeSuccess) {
      refetchUserData();
      setTradeMessage(`${t("linkSuccessfully")}`);

      setTimeout(() => {
        setTradeMessage("");
      }, 2000);
    }
  }, [tradeSuccess]);

  useEffect(() => {
    if (tradeError) {
      setTradeUrlErr(true);
      setTradeMessage(`${t("linkIncorrect")}`);
    }
  }, [tradeError]);

  const onSubmitTradeUrl = async (value) => {
    try {
      await addTradeUrl(value);
      setTradeUrlErr(false);
      refetchTradeUrl();
    } catch (err) {}
  };

  useEffect(() => {
    localStorage.setItem("isShowPanel", JSON.stringify(isShowPanel));
  }, [isShowPanel]);

  return (
    <div className="SkinsOutput">
      <div className="SkinsOutputWrapper">
        <button
          className="SkinsOutput-show_panel"
          onClick={() => setShowPanel(!isShowPanel)}
        >
          {isShowPanel ? `${t("hideLinkTrade")}` : `${t("linkTrade")}`}
        </button>
        {isShowPanel && (
          <>
            <div className="SkinsOutputContent">
              <p className="SkinsOutputContentTitle">
                {t("linkSearch")}{" "}
                <Link
                  to={"http://steamcommunity.com/my/tradeoffers/privacy"}
                  target="_blank"
                >
                  {t("site")}
                </Link>
              </p>
              <form
                className="TradeUrlForm"
                onSubmit={handleSubmitTradeUrl(onSubmitTradeUrl)}
              >
                <div className="inputLink">
                  <input
                    type="text"
                    {...registerTradeUrl("trade_url", {
                      required: "Это поле обязательно",
                      minLength: { value: 0, message: "Введите trade ссылку" },
                    })}
                    placeholder={t("inputTrade")}
                  />
                  <button className="saveBtn" type="submit">
                    {t("save")}
                  </button>
                </div>
                {tradeMessage ? (
                  <p className="errorMessage">{tradeMessage}</p>
                ) : (
                  <p>{setTradeMessage}</p>
                )}
              </form>
              <div className="outputAvailable">
                <span className="update">
                  <img src={link_img.update} alt="" />
                </span>
                <p>
                  {t("skinWithdrawal")}{" "}
                  {tradeUrlErr ? `${t("unavailable")}` : `${t("available")}`}!
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

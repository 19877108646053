import React, { useState } from "react";
import { GiveawayButton } from "./components/GiveawayButton";
import { GiveawayWarning } from "./components/GiveawayWarning";
import { GiveawayItem } from "./components/GiveawayItem";

import "./skinGiveaway.scss";
import { GiveawayPopup } from "./GiveawayPopup/GiveawayPopup";

export const SkinGiveaway = ({ setPayments }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="giveaway">
        <div className="mainWidht">
          <div className="giveaway__wrapper">
            <div className="giveaway__head">
              <GiveawayButton />
            </div>
            <div className="giveaway__content">
              <div className="giveaway__content-item">
                <GiveawayItem
                  name={"Ежедневная раздача"}
                  image={
                    "https://storage.take-drop.net/images/items/skins/weapon_deagle/aa_flames/65f614e1d54f5.png"
                  }
                  itemName={"Desert Eagle | Механо-пушка"}
                  rarity={"StatTrak™ Прямо с завода "}
                  day={"00"}
                  hour={"23"}
                  minutes={"59"}
                  seconds={"59"}
                  price={1000}
                  participants={123}
                  color={"pink"}
                  handleClick={() => setIsOpen(!isOpen)}
                />
                <GiveawayItem
                  name={"Ежедневная раздача"}
                  image={
                    "https://storage.take-drop.net/images/items/skins/weapon_ak47/cu_ak47_asiimov/65f615376a24d.png"
                  }
                  itemName={"Heading 4 → AK-47"}
                  rarity={"Прямо с завода"}
                  day={"00"}
                  hour={"23"}
                  minutes={"59"}
                  seconds={"59"}
                  price={1000}
                  participants={123}
                  color={"red"}
                  handleClick={() => setIsOpen(!isOpen)}
                />
                <GiveawayItem
                  name={"Ежемесячная раздача"}
                  image={
                    "https://storage.take-drop.net/images/items/skins/weapon_m4a1/cu_m4a1_howling/65f6156b05c99.png"
                  }
                  itemName={"M4A4 | Вой"}
                  rarity={"После полевых"}
                  day={"00"}
                  hour={"23"}
                  minutes={"59"}
                  seconds={"59"}
                  price={1000}
                  participants={123}
                  color={"gold"}
                  handleClick={() => setIsOpen(!isOpen)}
                />
              </div>
              <GiveawayWarning />
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <GiveawayPopup setIsOpen={setIsOpen} setPayments={setPayments} />
      )}
    </>
  );
};

import React from "react";

import "./ContentsCase.scss";
import { useTranslation } from "react-i18next";

export const ContentsCase = ({ items }) => {
  const { t } = useTranslation();

  return (
    <div className="ContentsCase">
      <h2>{t("contentCases")}</h2>
      <div className="ProfileBottom ContentWrapper">
        {items?.map((item, index) => (
          <div
            className={`ProfileBottomCard ${
              item.rarity === "Исключительный"
                ? "blue"
                : item.rarity === "Мастерский"
                ? " red"
                : item.rarity === "Заслуженный"
                ? "blue-army"
                : item.rarity === "Стандартное"
                ? "blue-army"
                : item.rarity === "Высшего класса"
                ? "blue"
                : item.rarity === "Примечательного типа"
                ? "blue-army"
                : item.rarity === "Экзотичного вида"
                ? " pink"
                : item.rarity === "Превосходный"
                ? "pink"
                : item.rarity === "Запрещённое"
                ? "purple"
                : item.rarity === "Промышленное качество"
                ? "blue"
                : item.rarity === "Тайное"
                ? "red"
                : item.rarity === "Ширпотреб"
                ? "green"
                : item.rarity === "Армейское качество"
                ? "blue-army"
                : item.rarity === "экстраординарного типа"
                ? "yellow"
                : item.rarity === "Контрабанда"
                ? "yellow"
                : item.rarity === "Засекреченное"
                ? "pink"
                : item.rarity === "Restricted"
                ? "purple"
                : item.rarity === "Industrial Grade"
                ? "blue"
                : item.rarity === "Covert"
                ? "red"
                : item.rarity === "Consumer Grade"
                ? "green"
                : item.rarity === "Mil-Spec"
                ? "blue-army"
                : item.rarity === "Extraordinary"
                ? "yellow"
                : item.rarity === "Contraband"
                ? "yellow"
                : item.rarity === "Classified"
                ? "pink"
                : item.rarity === "Remarkable"
                ? "blue"
                : item.rarity === "Masterpiece"
                ? " red"
                : item.rarity === "Distinguished"
                ? "blue-army"
                : item.rarity === "Standard"
                ? "blue-army"
                : item.rarity === "High Class"
                ? "blue"
                : item.rarity === "Exceptional"
                ? "blue-army"
                : item.rarity === "Exotic"
                ? " pink"
                : ""
            }`}
            key={index}
          >
            <div
              className={`ProfileCardInner ${
                item.rarity === "Исключительный"
                  ? "blue"
                  : item.rarity === "Мастерский"
                  ? " red"
                  : item.rarity === "Заслуженный"
                  ? "blue-army"
                  : item.rarity === "Стандартное"
                  ? "blue-army"
                  : item.rarity === "Высшего класса"
                  ? "blue"
                  : item.rarity === "Примечательного типа"
                  ? "blue-army"
                  : item.rarity === "Экзотичного вида"
                  ? " pink"
                  : item.rarity === "Превосходный"
                  ? "pink"
                  : item.rarity === "Запрещённое"
                  ? "purple"
                  : item.rarity === "Промышленное качество"
                  ? "blue"
                  : item.rarity === "Тайное"
                  ? "red"
                  : item.rarity === "Ширпотреб"
                  ? "green"
                  : item.rarity === "Армейское качество"
                  ? "blue-army"
                  : item.rarity === "экстраординарного типа"
                  ? "yellow"
                  : item.rarity === "Контрабанда"
                  ? "yellow"
                  : item.rarity === "Засекреченное"
                  ? "pink"
                  : item.rarity === "Restricted"
                  ? "purple"
                  : item.rarity === "Industrial Grade"
                  ? "blue"
                  : item.rarity === "Covert"
                  ? "red"
                  : item.rarity === "Consumer Grade"
                  ? "green"
                  : item.rarity === "Mil-Spec"
                  ? "blue-army"
                  : item.rarity === "Extraordinary"
                  ? "yellow"
                  : item.rarity === "Contraband"
                  ? "yellow"
                  : item.rarity === "Classified"
                  ? "pink"
                  : item.rarity === "Remarkable"
                  ? "blue"
                  : item.rarity === "Masterpiece"
                  ? " red"
                  : item.rarity === "Distinguished"
                  ? "blue-army"
                  : item.rarity === "Standard"
                  ? "blue-army"
                  : item.rarity === "High Class"
                  ? "blue"
                  : item.rarity === "Exceptional"
                  ? "blue-army"
                  : item.rarity === "Exotic"
                  ? " pink"
                  : ""
              }`}
            >
              <div
                className="ProfileBottomBlockRing"
                style={{
                  border: `2px solid ${
                    item.rarity === "Исключительный"
                      ? "#00A3FF"
                      : item.rarity === "Мастерский"
                      ? "#B12E2E"
                      : item.rarity === "Заслуженный"
                      ? "#0033B7"
                      : item.rarity === "Стандартное"
                      ? "#0033B7"
                      : item.rarity === "Высшего класса"
                      ? "#00A3FF"
                      : item.rarity === "Примечательного типа"
                      ? "#0033b7"
                      : item.rarity === "Экзотичного вида"
                      ? "#FF00BC"
                      : item.rarity === "Превосходный"
                      ? "#ff00bc"
                      : item.rarity === "Запрещённое"
                      ? "#9948FF"
                      : item.rarity === "Промышленное качество"
                      ? "#4D94E7"
                      : item.rarity === "Тайное"
                      ? "#DE2222"
                      : item.rarity === "Ширпотреб"
                      ? "#8FA6B9"
                      : item.rarity === "Армейское качество"
                      ? "#4C6AFF"
                      : item.rarity === "экстраординарного типа"
                      ? "#EFCC17"
                      : item.rarity === "Контрабанда"
                      ? "#EFCC17"
                      : item.rarity === "Засекреченное"
                      ? "#D72DE6"
                      : item.rarity === "Restricted"
                      ? "#7B02DC"
                      : item.rarity === "Industrial Grade"
                      ? "#00A3FF"
                      : item.rarity === "Covert"
                      ? "#B12E2E"
                      : item.rarity === "Consumer Grade"
                      ? "#8FA6B9"
                      : item.rarity === "Mil-Spec"
                      ? "#0033B7"
                      : item.rarity === "Extraordinary"
                      ? "#EFCC17"
                      : item.rarity === "Contraband"
                      ? "#EFCC17"
                      : item.rarity === "Classified"
                      ? "#FF00BC"
                      : item.rarity === "Remarkable"
                      ? "#00A3FF"
                      : item.rarity === "Masterpiece"
                      ? "#B12E2E"
                      : item.rarity === "Distinguished"
                      ? "#0033B7"
                      : item.rarity === "Standard"
                      ? "#0033B7"
                      : item.rarity === "High Class"
                      ? "#00A3FF"
                      : item.rarity === "Exceptional"
                      ? "#0033b7"
                      : item.rarity === "Exotic"
                      ? "#FF00BC"
                      : ""
                  }`,
                }}
              >
                <img src={item.image} alt="" />
              </div>
              <div className="ProfileBottomBlockDescription">
                <p className="name">{item.name}</p>
                <span
                  className="categoryName"
                  style={{
                    color: `${
                      item.rarity === "Исключительный"
                        ? "#33687e"
                        : item.rarity === "Мастерский"
                        ? " #7f3535"
                        : item.rarity === "Заслуженный"
                        ? "#33687e"
                        : item.rarity === "Стандартное"
                        ? "#33687e"
                        : item.rarity === "Высшего класса"
                        ? "#33687e"
                        : item.rarity === "Примечательного типа"
                        ? "#33687e"
                        : item.rarity === "Экзотичного вида"
                        ? " #7f357b"
                        : item.rarity === "Превосходный"
                        ? "#7f357b"
                        : item.rarity === "Запрещённое"
                        ? "#9948FF"
                        : item.rarity === "Промышленное качество"
                        ? "#4D94E7"
                        : item.rarity === "Тайное"
                        ? "#DE2222"
                        : item.rarity === "Ширпотреб"
                        ? "#8FA6B9"
                        : item.rarity === "Армейское качество"
                        ? "#4C6AFF"
                        : item.rarity === "экстраординарного типа"
                        ? "#7c842d"
                        : item.rarity === "Контрабанда"
                        ? "#7c842d"
                        : item.rarity === "Засекреченное"
                        ? "#D72DE6"
                        : item.rarity === "Restricted"
                        ? "#7257a8"
                        : item.rarity === "Industrial Grade"
                        ? "#33687e"
                        : item.rarity === "Covert"
                        ? "#7f3535"
                        : item.rarity === "Consumer Grade"
                        ? "#307d58"
                        : item.rarity === "Mil-Spec"
                        ? "#33687e"
                        : item.rarity === "Extraordinary"
                        ? "#7c842d"
                        : item.rarity === "Contraband"
                        ? "#7c842d"
                        : item.rarity === "Classified"
                        ? "#7f357b"
                        : item.rarity === "Remarkable"
                        ? "#33687e"
                        : item.rarity === "Masterpiece"
                        ? " #7f3535"
                        : item.rarity === "Distinguished"
                        ? "#33687e"
                        : item.rarity === "Standard"
                        ? "#33687e"
                        : item.rarity === "High Class"
                        ? "#33687e"
                        : item.rarity === "Exceptional"
                        ? "#336872"
                        : item.rarity === "Exotic"
                        ? " #7f357b"
                        : item.rarity === "Exotic"
                        ? "#7f357b"
                        : ""
                    }`,
                  }}
                >
                  {item.rarity}1
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

import { Routes, Route } from "react-router-dom";

import Footer from "../../components/Footer/Footer";

import Header from "../../components/Header/Header";

import "./App.scss";

import { Profile } from "../../pages/Profile";
import { Home } from "../../pages/Home";
import { SelectedCase } from "../../pages/SelectedCase";
import { User } from "../../pages/User";
import { PrivacyPolicy } from "../../pages/PrivacyPolicy/PrivacyPolicy";
import { TermsOfService } from "../../pages/TermsOfService/TermsOfService";
import { FAQ } from "../../pages/FAQ/FAQ";

import { useState } from "react";
import { Payments } from "../../components/Payments/Payments";
import { Notif } from "../../components/Payments/components/Notif";

function App() {
  const [login, setLogin] = useState(false);
  const [isMuted, setMuted] = useState(() => {
    return localStorage.getItem("muted") === "true";
  });
  const [isPayments, setPayments] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [payErr, setPayErr] = useState();

  const isAuth = document.cookie
    ?.split("; ")
    .find((row) => row?.startsWith("access_token="));

  if (!isAuth) {
  }

  return (
    <div className="App">
      <Header
        setLogin={setLogin}
        login={login}
        isMuted={isMuted}
        setMuted={setMuted}
        setPayments={setPayments}
      />

      <div className="main-wrapper" style={{ position: "relative" }}>
        <Routes>
          <Route path="/" element={<Home setPayments={setPayments} />}></Route>
          <Route path="profile" element={<Profile />}></Route>
          <Route
            path="case/:url"
            element={
              <SelectedCase
                setLogin={setLogin}
                login={login}
                isMuted={isMuted}
                setMuted={setMuted}
                setPayments={setPayments}
              />
            }
          ></Route>
          <Route path="user/:user_id" element={<User />}></Route>
          <Route path="agreement" element={<TermsOfService />}></Route>
          <Route path="faq" element={<FAQ />}></Route>
          <Route path="privacy-policy" element={<PrivacyPolicy />}></Route>
        </Routes>
      </div>
      <Footer />
      {isPayments && (
        <Payments
          setPayments={setPayments}
          setShowNotification={setShowNotification}
          setPayErr={setPayErr}
        />
      )}
      <Notif
        showNotification={showNotification}
        setPayErr={setPayErr}
        payErr={payErr}
      />
    </div>
  );
}

export default App;

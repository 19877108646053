import {React} from "react";
import { useNavigate } from "react-router-dom";
import "./ToHome.scss";
import { useGetUserQuery } from "../../redux/cases/cases.js";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const ToHome = () => {
    const navigate = useNavigate();

  const { t } = useTranslation();

  const { refetch } = useGetUserQuery(null);

  const handleLogoutAccount = () => {
    document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=.take-drop.net';

    navigate("/");

    setTimeout(() => {
      window.location.reload();
    }, 100);

    window.scrollTo(0, 0);
  };

  return (
    <div className="home-btn">
      <Link to="/" className="ToHome">
        <span>{t("toHome")}</span>
      </Link>
      <button className="logout" onClick={handleLogoutAccount}>
        <span>{t("logout")}</span>
      </button>
    </div>
  );
};

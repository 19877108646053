import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "../OpenCase/CaseOpen/CaseOpen.scss";

import { useSelector } from "react-redux";

export const WinningItem = ({
  winner,
  selectCountCase,
  dropItems,
  soldItems,
  color,
  handleSaleItem,
  fastWin,
  handleOpenMore,
  isSpinning,
  sold,
  handleSaleAllItem,
}) => {
  const { t } = useTranslation();
  const soldsItems = useSelector((state) => state.soldItemsSlice);
  const [formattedSum, setFormattedSum] = useState(0);

  useEffect(() => {
    const price = soldsItems.map((item) => item.price);
    const sum = price.reduce((acc, curr) => acc + curr, 0);
    setFormattedSum(sum.toFixed(2));
  }, [soldsItems]);

  const handleSaleAllItems = () => {
    const validIds = soldsItems
      .filter((item) => !item.deleted)
      .map((item) => item.id);
    handleSaleAllItem(validIds);
  };

  return (
    <>
      {winner && fastWin && selectCountCase > 0 && (
        <div className="winning">
          <div
            className={`winning_item ${
              selectCountCase === 1
                ? "one"
                : selectCountCase === 2
                ? "two"
                : selectCountCase === 3
                ? "three"
                : selectCountCase === 4
                ? "four"
                : selectCountCase === 5
                ? "five"
                : selectCountCase === 10
                ? "ten"
                : ""
            }`}
          >
            {dropItems &&
              dropItems.map((item, index) => (
                <div
                  className={`winning_item-lot ${
                    item.rarity === "Исключительный"
                      ? "blue"
                      : item.rarity === "Мастерский"
                      ? " red"
                      : item.rarity === "Заслуженный"
                      ? "blue-army"
                      : item.rarity === "Стандартное"
                      ? "blue-army"
                      : item.rarity === "Высшего класса"
                      ? "blue"
                      : item.rarity === "Примечательного типа"
                      ? "blue-army"
                      : item.rarity === "Экзотичного вида"
                      ? " pink"
                      : item.rarity === "Превосходный"
                      ? "pink"
                      : item.rarity === "Запрещённое"
                      ? "purple"
                      : item.rarity === "Промышленное качество"
                      ? "blue"
                      : item.rarity === "Тайное"
                      ? "red"
                      : item.rarity === "Ширпотреб"
                      ? "green"
                      : item.rarity === "Армейское качество"
                      ? "blue-army"
                      : item.rarity === "экстраординарного типа"
                      ? "yellow"
                      : item.rarity === "Контрабанда"
                      ? "yellow"
                      : item.rarity === "Засекреченное"
                      ? "pink"
                      : item.rarity === "Restricted"
                      ? "purple"
                      : item.rarity === "Industrial Grade"
                      ? "blue"
                      : item.rarity === "Covert"
                      ? "red"
                      : item.rarity === "Consumer Grade"
                      ? "green"
                      : item.rarity === "Mil-Spec"
                      ? "blue-army"
                      : item.rarity === "Extraordinary"
                      ? "yellow"
                      : item.rarity === "Contraband"
                      ? "yellow"
                      : item.rarity === "Classified"
                      ? "pink"
                      : item.rarity === "Remarkable"
                      ? "blue"
                      : item.rarity === "Masterpiece"
                      ? " red"
                      : item.rarity === "Distinguished"
                      ? "blue-army"
                      : item.rarity === "Standard"
                      ? "blue-army"
                      : item.rarity === "High Class"
                      ? "blue"
                      : item.rarity === "Exceptional"
                      ? "blue-army"
                      : item.rarity === "Exotic"
                      ? " pink"
                      : ""
                  }`}
                  key={index}
                >
                  <div
                    className={`winning_item-lot_shadow ${
                      item.rarity === "Исключительный"
                        ? "blue"
                        : item.rarity === "Мастерский"
                        ? " red"
                        : item.rarity === "Заслуженный"
                        ? "blue-army"
                        : item.rarity === "Стандартное"
                        ? "blue-army"
                        : item.rarity === "Высшего класса"
                        ? "blue"
                        : item.rarity === "Примечательного типа"
                        ? "blue-army"
                        : item.rarity === "Экзотичного вида"
                        ? " pink"
                        : item.rarity === "Превосходный"
                        ? "pink"
                        : item.rarity === "Запрещённое"
                        ? "purple"
                        : item.rarity === "Промышленное качество"
                        ? "blue"
                        : item.rarity === "Тайное"
                        ? "red"
                        : item.rarity === "Ширпотреб"
                        ? "green"
                        : item.rarity === "Армейское качество"
                        ? "blue-army"
                        : item.rarity === "экстраординарного типа"
                        ? "yellow"
                        : item.rarity === "Контрабанда"
                        ? "yellow"
                        : item.rarity === "Засекреченное"
                        ? "pink"
                        : item.rarity === "Restricted"
                        ? "purple"
                        : item.rarity === "Industrial Grade"
                        ? "blue"
                        : item.rarity === "Covert"
                        ? "red"
                        : item.rarity === "Consumer Grade"
                        ? "green"
                        : item.rarity === "Mil-Spec"
                        ? "blue-army"
                        : item.rarity === "Extraordinary"
                        ? "yellow"
                        : item.rarity === "Contraband"
                        ? "yellow"
                        : item.rarity === "Classified"
                        ? "pink"
                        : item.rarity === "Remarkable"
                        ? "blue"
                        : item.rarity === "Masterpiece"
                        ? " red"
                        : item.rarity === "Distinguished"
                        ? "blue-army"
                        : item.rarity === "Standard"
                        ? "blue-army"
                        : item.rarity === "High Class"
                        ? "blue"
                        : item.rarity === "Exceptional"
                        ? "blue-army"
                        : item.rarity === "Exotic"
                        ? " pink"
                        : ""
                    }`}
                  ></div>
                  <div
                    className={`winning_item-lot_border ${
                      item.rarity === "Исключительный"
                        ? "blue"
                        : item.rarity === "Мастерский"
                        ? " red"
                        : item.rarity === "Заслуженный"
                        ? "blue-army"
                        : item.rarity === "Стандартное"
                        ? "blue-army"
                        : item.rarity === "Высшего класса"
                        ? "blue"
                        : item.rarity === "Примечательного типа"
                        ? "blue-army"
                        : item.rarity === "Экзотичного вида"
                        ? " pink"
                        : item.rarity === "Превосходный"
                        ? "pink"
                        : item.rarity === "Запрещённое"
                        ? "purple"
                        : item.rarity === "Промышленное качество"
                        ? "blue"
                        : item.rarity === "Тайное"
                        ? "red"
                        : item.rarity === "Ширпотреб"
                        ? "green"
                        : item.rarity === "Армейское качество"
                        ? "blue-army"
                        : item.rarity === "экстраординарного типа"
                        ? "yellow"
                        : item.rarity === "Контрабанда"
                        ? "yellow"
                        : item.rarity === "Засекреченное"
                        ? "pink"
                        : item.rarity === "Restricted"
                        ? "purple"
                        : item.rarity === "Industrial Grade"
                        ? "blue"
                        : item.rarity === "Covert"
                        ? "red"
                        : item.rarity === "Consumer Grade"
                        ? "green"
                        : item.rarity === "Mil-Spec"
                        ? "blue-army"
                        : item.rarity === "Extraordinary"
                        ? "yellow"
                        : item.rarity === "Contraband"
                        ? "yellow"
                        : item.rarity === "Classified"
                        ? "pink"
                        : item.rarity === "Remarkable"
                        ? "blue"
                        : item.rarity === "Masterpiece"
                        ? " red"
                        : item.rarity === "Distinguished"
                        ? "blue-army"
                        : item.rarity === "Standard"
                        ? "blue-army"
                        : item.rarity === "High Class"
                        ? "blue"
                        : item.rarity === "Exceptional"
                        ? "blue-army"
                        : item.rarity === "Exotic"
                        ? " pink"
                        : ""
                    }`}
                  >
                    <img src={item.image} alt={item.name} />
                  </div>
                  <div className="winning_item-lot_description">
                    <h3>{item.name}</h3>
                    <button
                      className="winning_item-lot_sale"
                      onClick={() => handleSaleItem(item.id)}
                      disabled={soldItems.includes(item.id) || sold}
                    >
                      <span>{sold ? t("soldFor") : t("sellFor")}</span>
                      {item.price} ₽
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      <div className={`CaseOpenBtn ${selectCountCase > 1 ? "sm" : ""}`}>
        {winner && selectCountCase === 1 ? (
          dropItems &&
          dropItems.map((item, index) => (
            <div
              className="button"
              style={{ position: "relative" }}
              key={index}
            >
              <button
                className={`tryAgain btn ${color}`}
                onClick={handleOpenMore}
                disabled={isSpinning}
              >
                {t("tryAgain")}
              </button>
              <button
                className="sell btn"
                onClick={() => handleSaleItem(item.id)}
                disabled={soldItems.includes(item.id)}
              >
                {!sold
                  ? `${t("sellFor")} ${formattedSum}₽`
                  : `${t("soldFor")} ${formattedSum} ₽`}{" "}
              </button>
            </div>
          ))
        ) : (
          <div className="button" style={{ position: "relative" }}>
            <button
              className={`sell btn ${color}`}
              onClick={handleSaleAllItems}
              disabled={isSpinning || sold}
            >
              {!sold
                ? `${t("sellFor")} ${formattedSum}₽`
                : `${t("soldFor")} ${formattedSum} ₽`}
            </button>
            <button
              className={`tryAgain btn ${color}`}
              onClick={handleOpenMore}
              disabled={isSpinning}
            >
              {t("tryAgain")}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

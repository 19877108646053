import {React} from "react";

import "./buttonHome.scss";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const ButtonHome = () => {
  const { t } = useTranslation();

  return (
    <button>
      <Link
        className="home-btn-two"
        to={"/"}
        onClick={() => window.scrollTo(0, 0)}
      >
        {t("toHome")}
      </Link>
    </button>
  );
};

import React from "react";
import link_img from "../../../img/link_img";
import { useTranslation } from "react-i18next";

export const GiveawayWarning = () => {
  const { t } = useTranslation();

  return (
    <div className="giveaway__content-warning">
      <div className="giveaway__warning-inner">
        <p>{t("work_name")}</p>
        <div className="giveaway__warning-img">
          <img src={link_img.work} alt="work" />
        </div>
      </div>
    </div>
  );
};

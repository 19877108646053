import openingCaseSound from "./opening case.mp3";
import step from "./step.mp3";
import case_sound_sharpness from "./case_sound_sharpness_up.mp3";

const sounds = {
  openingCaseSound: openingCaseSound,
  step: step,
  case_sound_sharpness: case_sound_sharpness,
};

export default sounds;

import React from "react";

import "./termsOfService.scss";

import { Link } from "react-router-dom";
import { ButtonHome } from "../../components/ButtonHome/ButtonHome";
import { EscapeToHome } from "../../components/EscapeToHome/EscapeToHome";
import link_img from "../../img/link_img";

export const TermsOfService = () => {
  return (
    <div className="terms-service">
      <div className="mainWidht">
        <ButtonHome />
         {/* this component only return to home page! */}
         <EscapeToHome />
        <div className="terms-service__wrapper">
          <h2 className="terms-service__content-title">take-drop</h2>
          <div className="terms-service__content-block">
            <div className="terms-service__content-block_inner">
              <div className="terms-service__content-block_logo">
                <img src={link_img.termsLogo} alt="logo" />
              </div>
              <div className="terms-service__content-block_text">
                <p>
                  <span className="terms-service__bold">Внимание!</span>{" "}
                  Пожалуйста ознакомьтесь с данным пользовательским соглашением
                  перед началом использования веб-сайта take-drop.net и его
                  программного обеспечения. Регистрация или авторизация на сайте
                  будет рассматриваться как ваше согласие с условиями данного
                  пользовательского соглашения. Если вы не согласны с условиями
                  данного пользовательского соглашения, пожалуйста, не
                  производите регистрацию или авторизацию на сайте и не
                  используйте его программное обеспечение.
                </p>
              </div>
            </div>
          </div>
          <div className="terms-service__content">
            <h2 className="terms-service__content-title">
              Пользовательское соглашение
            </h2>
            <div className="terms-service__text-content">
              <div className="terms-service__text-block">
                <h4 className="terms-service__text-block-subitem">
                  1. Термины и определения
                </h4>
                <div className="terms-service__text-block-items">
                  <p className="terms-service__text-block-item">
                    <span className="terms-service__bold">1.1</span> В данном
                    пользовательском соглашении, если ясно не указано иное,
                    следующие термины будут использоваться со следующими
                    определениями:
                  </p>
                  <p className="terms-service__text-block-item">
                    "<span>Steam</span>", "<span>Сервис Steam</span>":
                    Онлайн-платформа, предоставляемая компанией Valve,
                    владельцем инвентарных элементов.
                  </p>
                  <p className="terms-service__text-block-item">
                    "<span>Владелец</span>": Una Planeta LTD: Номер компании
                    415100090, Страна: Sakartvelo, K. Poti, Rustavelis Rkali 12,
                    Bina 21, Georgia.
                  </p>
                  <p className="terms-service__text-block-item">
                    "<span>Инвентарь</span>": Возможность использования одного
                    из элементов, указанных в Кейсе, в соответствии с
                    пользовательским соглашением Steam, доступным по адресу
                    <Link
                      to={
                        "http://store.steampowered.com/subscriber_agreement/englis	h/"
                      }
                    >
                      http://store.steampowered.com/subscriber_agreement/englis
                      h/
                    </Link>
                    .
                  </p>
                  <p className="terms-service__text-block-item">
                    "<span>Случай</span>": Совокупность защищенных страниц на
                    сайте, созданная при регистрации Пользователя, для
                    использования всеми или некоторыми функциональными
                    возможностями сайта. Доступ к Личному кабинету
                    осуществляется следующим образом:
                  </p>
                  <p className="terms-service__text-block-item">
                    Нажав кнопку "Войти через Steam" на сайте, Пользователь
                    будет автоматически перенаправлен на сайт по адресу
                    <Link to={"https://steamcommunity.com/"}>
                      https://steamcommunity.com/
                    </Link>
                    ;
                  </p>
                  <p className="terms-service__text-block-item">
                    Введя имя пользователя (подписчика) Steam и пароль в
                    соответствующие поля, а затем нажав кнопку "Войти",
                    расположенную по адресу
                    <Link to={"https://steamcommunity.com/"}>
                      https://steamcommunity.com/
                    </Link>
                    ;
                  </p>
                  <p className="terms-service__text-block-item">
                    Либо открыв сайт, если Пользователь ранее не выходил из
                    Личного кабинета, нажав кнопку "Выйти".
                  </p>
                  <p className="terms-service__text-block-item">
                    "<span>Пользователь</span>": Физическое лицо, имеющее
                    учетную запись Steam, заключившее Соглашение с Владельцем.
                  </p>
                  <p className="terms-service__text-block-item">
                    "<span>Сайт</span>": Совокупность информации, текстов,
                    графических элементов, дизайна, изображений, фото- и
                    видеоматериалов, программного обеспечения и прочих
                    результатов интеллектуальной деятельности (за исключением
                    Описи), содержащихся в информационной системе,
                    обеспечивающей доступность такой информации в сети Интернет
                    в течение доменной зоны {"{ domain }"}.
                  </p>
                  <p className="terms-service__text-block-item">
                    "<span>Соглашение</span>": Настоящее Пользовательское
                    соглашение.
                  </p>
                  <p className="terms-service__text-block-item">
                    "<span>Стороны</span>": Владелец и Пользователь.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>1.2</span> Все прочие термины и определения,
                    используемые в тексте Соглашения, толкуются Сторонами в
                    соответствии с законодательством Грузии, а также правилами
                    толкования соответствующих терминов, принятыми в сети
                    Интернет.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>1.3</span> Названия заголовков (статей) Соглашения
                    предназначены исключительно для удобства пользования текстом
                    Соглашения и буквального юридического значения не имеют.
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="terms-service__text-block">
                <h4 className="terms-service__text-block-subitem">
                  2. Заключение Соглашения
                </h4>
                <div className="terms-service__text-block-items">
                  <p className="terms-service__text-block-item">
                    <span>2.1.</span> Текст Соглашения, регулярно доступный в
                    интернете по адресу https://take-drop.net/agreement и
                    доступный при регистрации (авторизации) на Сайте, включает
                    все существенные условия Соглашения и является предложением
                    Владельца заключить Соглашение с любым полностью
                    дееспособным третьим лицом, использующим Сайт, на указанных
                    в тексте Соглашения условиях.
                  </p>
                  <p className="terms-service__text-block-item">
                    Таким образом, текст Соглашения составляет публичную оферту
                    в соответствии с законодательством Грузии.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>2.2.</span> Принятием настоящей оферты считается
                    последовательное осуществление третьим лицом следующих
                    действий:
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>2.2.1.</span> Ознакомление с условиями Соглашения;
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>2.2.2.</span> Установка отметки в специальном поле под
                    заголовком "Я принимаю условия пользовательского
                    соглашения";
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>2.2.3.</span>
                    Авторизация на Сайте через Steam и осуществление входа в
                    открывшемся окне через уже имеющийся аккаунт Steam или
                    создание нового аккаунта Steam.
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="terms-service__text-block">
                <h4 className="terms-service__text-block-subitem">
                  3. Предмет Соглашения
                </h4>
                <div className="terms-service__text-block-items">
                  <p className="terms-service__text-block-item">
                    <span>3.1.</span> Владелец предоставляет Пользователю:
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>3.1.1.</span> Безвозмездную простую (неисключительную)
                    лицензию на использование Сайта и его программного
                    обеспечения в соответствии с их предназначением, как это
                    предусмотрено явными пользовательскими функциями Сайта и
                    Личного кабинета;
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>3.1.2.</span> Возмездную простую (неисключительную)
                    лицензию на использование Кейса в соответствии с его
                    назначением, при этом стоимость лицензии на использование
                    определенного Кейса указана на Сайте.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>3.2.</span> Указанная в пункте 3.1.1 Соглашения
                    лицензия предоставляется Пользователю на срок и в пределах
                    территории, на которой Сайт и Личный кабинет доступны для
                    Пользователя.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>3.3.</span> Указанная в пункте 3.1.2 Соглашения
                    лицензия предоставляется Пользователю в пределах территории,
                    на которой Кейс доступен для Пользователя в период с момента
                    оплаты
                  </p>
                  <p className="terms-service__text-block-item">
                    Пользователем вознаграждения за использование конкретного
                    Кейса до момента открытия Инвентаря с помощью такого Кейса.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>3.4.</span> Пользователю запрещается:
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>3.4.1.</span> Обходить технические ограничения,
                    установленные на Сайте и в Кейсе;
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>3.4.2.</span> Исследовать технологию, декомпилировать
                    или дизассемблировать Сайт, Кейс и Личный кабинет, за
                    исключением случаев, прямо предусмотренных законодательством
                    Грузии;
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>3.4.3.</span> Создавать копии экземпляров Сайта,
                    Кейсов и Личного кабинета, а также их дизайна;
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>3.4.4.</span> Изменять Сайт, Кейсы и Личный кабинет
                    каким бы то ни было способом;
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>3.4.5.</span> Осуществлять действия, направленные на
                    изменение функционирования и работоспособности Сайта, Кейсов
                    и Личного кабинета;
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>3.4.6.</span> Администрация оставляет за собой право
                    блокировки пользователя без объяснения причины;
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>3.4.7.</span> Создавать мультиаккаунты, за что
                    последует бан
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="terms-service__text-block">
                <h4 className="terms-service__text-block-subitem">
                  4. Функции Сайта, Кейса и Личного кабинета
                </h4>
                <div className="terms-service__text-block-items">
                  <p className="terms-service__text-block-item">
                    <span>4.1.</span> Пользователь через Сайт имеет возможность:
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>4.1.1.</span> ознакомиться с содержанием и
                    характеристиками Инвентаря, выбор которого происходит через
                    определенный Кейс, и стоимостью лицензии на использование
                    такого Кейса;
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>4.1.2.</span> приобрести лицензию на использование
                    Кейса и получить соответствующий Инвентарь в соответствии с
                    условиями Соглашения.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>4.2.</span> Пользователь через Кейс может получить
                    один из доступных на странице с Кейсом Инвентарей.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>4.3.</span> Пользователь через Личный кабинет может:
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>4.3.1.</span> принять Инвентарь в свой аккаунт Steam;
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>4.3.2.</span> в течение 1 (одного) часа после
                    получения Инвентаря через использование Кейса осуществить
                    его отчуждение за бонусные баллы в соответствии с
                    количеством, указанным в описании Инвентаря в Личном
                    кабинете, что дает право на скидку при оплате лицензионного
                    вознаграждения за Кейсы.
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="terms-service__text-block">
                <h4 className="terms-service__text-block-subitem">
                  5. Приемка Инвентаря
                </h4>
                <div className="terms-service__text-block-items">
                  <p className="terms-service__text-block-item">
                    <span>5.1.</span> С момента получения Инвентаря через
                    использование Кейса и его отображения в Личном кабинете,
                    Пользователь в течение 1 (одного) часа имеет возможность
                    принять Инвентарь в свой аккаунт Steam или осуществить
                    отчуждение Инвентаря через Личный кабинет.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>5.2.</span> Приемка Инвентаря в аккаунт Steam возможна
                    при соблюдении Пользователем указанных на Сайте и в Личном
                    кабинете настроек аккаунта Steam и Личного кабинета.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>5.3.</span> Для приемки Инвентаря в аккаунт Steam, при
                    соблюдении требований пункта 5.2 Соглашения, Пользователь
                    должен перейти по ссылке «ЗАБРАТЬ», размещенной в Личном
                    кабинете в описании соответствующего Инвентаря в течение 1
                    (одного) часа с момента его получения.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>5.4.</span> Пользователь также может осуществить
                    отчуждение полученного, но еще не принятого в аккаунт Steam
                    Инвентаря за бонусные баллы, указанные в описании Инвентаря
                    в Личном кабинете, при этом один бонусный балл дает право на
                    скидку только при оплате лицензионного вознаграждения за
                    Кейсы. Стороны согласовали, что эти бонусные баллы не
                    являются денежными средствами и не подлежат возврату или
                    обмену.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>5.5.</span> Для отчуждения Инвентаря Пользователь
                    должен перейти по соответствующей ссылке, размещенной в
                    Личном кабинете в описании Инвентаря, в течение 1 (одного)
                    часа с момента его получения. Если Пользователь не проявляет
                    активности в течение указанного времени, полученный, но еще
                    не принятый в аккаунт Steam Инвентарь будет автоматически
                    отчужден.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>5.6.</span> Бонусные баллы, полученные при отчуждении
                    Инвентаря, отображаются в Личном кабинете.
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="terms-service__text-block m">
                <h4 className="terms-service__text-block-subitem">
                  6. Вознаграждение Владельца
                </h4>
                <div className="terms-service__text-block-items">
                  <p className="terms-service__text-block-item">
                    <span>6.1.</span> Пользователь выплачивает лицензионное
                    вознаграждение за предоставленное Владельцем право
                    использования Кейса в размере, указанном на Сайте и
                    соответствующей странице Кейса.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>6.2.</span> Лицензионное вознаграждение, указанное в
                    пункте 6.1 Соглашения, оплачивается Пользователем из заранее
                    переданных денежных средств посредством платежного сервиса,
                    информация о котором доступна на момент оплаты и
                    отображается в Личном кабинете.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>6.3.</span> Передача денежных средств Владельцу
                    осуществляется способом, указанным на Сайте, с учетом
                    требований соответствующего платежного сервиса,
                    привлеченного Владельцем.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>6.4.</span>
                    Моментом выплаты лицензионного вознаграждения является
                    момент списания соответствующей суммы денежных средств, что
                    отражается изменением баланса в Личном кабинете
                    Пользователя.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>6.5.</span> Оплата лицензионного вознаграждения
                    осуществляется в соответствии с положениями раздела и
                    учитывает пункт 5.6 Соглашения.
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="terms-service__text-block m">
                <h4 className="terms-service__text-block-subitem">
                  7. Персональные данные
                </h4>
                <div className="terms-service__text-block-items">
                  <p className="terms-service__text-block-item">
                    <span>7.1.</span> Пользователь соглашается на обработку
                    своей информации, включая персональные данные,
                    предоставленные при использовании Сайта, в частности,
                    данных, указанных в аккаунте Пользователя в Steam.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>7.2.</span> Обработка персональных данных включает
                    запись, систематизацию, хранение, обновление, использование,
                    передачу, включая трансграничную, обезличивание,
                    блокирование, удаление и уничтожение данных, за исключением
                    специальных категорий, требующих письменного согласия
                    Пользователя согласно действующему законодательству.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>7.3.</span> Обработка персональных данных
                    осуществляется для выполнения обязательств по Соглашению,
                    регистрации на Сайте, приобретения лицензии на использование
                    Кейса, получения Инвентаря, а также для направления
                    информационных сообщений на адрес электронной почты
                    Пользователя.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>7.4.</span> Пользователь в любое время может отозвать
                    свое согласие на обработку персональных данных, направив
                    уведомление Владельцу по адресу, указанному в Соглашении.
                    Отзыв согласия влечет прекращение действия Соглашения, за
                    исключением случаев, предусмотренных законом.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>7.5.</span> Любые дополнительные условия обработки
                    персональных данных могут быть указаны в документах на
                    Сайте. В случае противоречий применяются условия документа.
                  </p>
                  <p className="terms-service__text-block-item">
                    <sapn>7.6.</sapn> Пользователь соглашается на получение
                    рекламных материалов от Владельца или аффилированных лиц на
                    адрес электронной почты, указанный при регистрации в
                    аккаунте Steam. Согласие на получение рекламных материалов
                    может быть отозвано в любое время.
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="terms-service__text-block">
                <h4 className="terms-service__text-block-subitem">
                  8. Ограничение ответственности
                </h4>
                <div className="terms-service__text-block-items">
                  <p className="terms-service__text-block-item">
                    <span>8.1</span>. Владелец не несет ответственность за
                    убытки, возникшие у Пользователя из-за неправомерных
                    действий третьих лиц, включая неправомерный доступ к Личному
                    кабинету.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>8.2</span>. Владелец не является правообладателем
                    объектов Инвентаря и не определяет их использование.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>8.3</span>. Пользователь руководствуется лицензионным
                    соглашением Steam относительно Инвентаря.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>8.4</span>. Сайт предоставляется "как есть", и
                    Пользователь несет риск его использования.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>8.5</span>. Владелец не гарантирует соответствие Сайта
                    требованиям Пользователя и непрерывность его работы.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>8.6</span>. Ошибки на Сайте, вызванные техническими
                    проблемами, являются обстоятельствами непреодолимой силы.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>8.7</span>. Владелец вправе передавать права и
                    обязанности по Соглашению третьим лицам.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>8.8</span>. Размер возмещения убытков ограничен первой
                    транзакцией Пользователя.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>8.9</span>. При нарушении условий Соглашения Владелец
                    вправе прекратить доступ Пользователя к Личному кабинету.
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="terms-service__text-block m">
                <h4 className="terms-service__text-block-subitem">
                  9. Порядок разрешения споров
                </h4>
                <div className="terms-service__text-block-items">
                  <p className="terms-service__text-block-item">
                    <sapn>9.1.</sapn> Все споры, разногласия и претензии,
                    возникшие в связи с исполнением, расторжением или признанием
                    недействительности Соглашения, Стороны будут стараться
                    решить путем переговоров. Сторона, выдвигающая претензии
                    и/или разногласия, направляет другой Стороне уведомление с
                    указанием претензий и/или разногласий согласно пункту 9.1
                    Соглашения.
                  </p>
                  <p className="terms-service__text-block-item">
                    <spna>9.2.</spna> Если ответ на уведомление не будет получен
                    отправившей его Стороной в течение 30 (тридцати) рабочих
                    дней с даты отправки соответствующего уведомления, или если
                    Стороны не придут к соглашению по претензиям и/или
                    разногласиям, спор будет передан на рассмотрение суда по
                    месту нахождения Владельца.
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="terms-service__text-block">
                <h4 className="terms-service__text-block-subitem">
                  10. Заключительные положения
                </h4>
                <div className="terms-service__text-block-items">
                  <p className="terms-service__text-block-item">
                    <span>10.1.</span> Стороны подтверждают, что при исполнении
                    (изменении, дополнении, прекращении) Соглашения, а также при
                    ведении переписки по указанным вопросам, могут
                    использоваться аналоги собственноручной подписи Сторон.
                    Стороны признают, что все уведомления, сообщения, соглашения
                    и документы, подписанные аналогами собственноручной подписи,
                    имеют юридическую силу и обязательны для исполнения.
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>
                      10.2. Стороны соглашаются, что все уведомления, сообщения,
                      соглашения, документы и письма, отправленные с
                      использованием уполномоченных адресов электронной почты и
                      Личного кабинета, считаются отправленными и подписанными
                      Сторонами, если обратное не указано в письме.
                    </span>
                  </p>
                  <p className="terms-service__text-block-item">
                    <sapn>10.3.</sapn> Уполномоченными адресами электронной
                    почты Сторон считаются:
                  </p>
                  <p className="terms-service__text-block-item">
                    <sapn>10.3.1.</sapn> для Владельца: info@take-drop.net;
                  </p>
                  <p className="terms-service__text-block-item">
                    <sapn>10.3.2.</sapn> для Пользователя: адрес электронной
                    почты, указанный при регистрации аккаунта в Steam.
                  </p>
                  <p className="terms-service__text-block-item">
                    <sapn>10.4.</sapn> Стороны обязуются сохранять
                    конфиденциальность учетных данных и информации о доступе к
                    уполномоченным адресам электронной почты и Личному кабинету,
                    не разглашать их и не передавать третьим лицам. Они сами
                    определяют порядок доступа к этой информации.
                  </p>
                  <p className="terms-service__text-block-item">
                    <sapn>10.5.</sapn> До получения уведомления о нарушении
                    конфиденциальности, действия и документы, отправленные через
                    уполномоченный адрес электронной почты, считаются
                    совершенными и направленными соответствующей Стороной.
                    Права, обязанности и ответственность переходят к этой
                    Стороне.
                  </p>
                  <p className="terms-service__text-block-item">
                    <sapn>10.6.</sapn> При использовании Личного кабинета,
                    действия и документы, отправленные через него до получения
                    уведомления о нарушении конфиденциальности, приписываются
                    Пользователю. Права, обязанности и ответственность переходят
                    к Пользователю.
                  </p>
                  <p className="terms-service__text-block-item">
                    <sapn>10.7.</sapn> Администрация имеет право запросить
                    документы для подтверждения личности и платежного аккаунта
                    пользователя.
                  </p>
                  <p className="terms-service__text-block-item">
                    <sapn>10.8.</sapn> Вывод предметов доступен только на
                    аккаунт, с которого были открыты кейсы.
                  </p>
                  <p className="terms-service__text-block-item">
                    <sapn>10.9.</sapn> Возврат средств не предусмотрен после их
                    использования на сайте.
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="terms-service__text-block">
                <h4 className="terms-service__text-block-subitem">
                  11. Изменение условий Соглашения
                </h4>
                <div className="terms-service__text-block-items">
                  <p className="terms-service__text-block-item">
                    <sapn>11.1.</sapn> Владелец имеет право в одностороннем
                    порядке изменять условия Соглашения. Изменения вступают в
                    силу с момента публикации новой версии Соглашения по адресу
                    <Link to={"https://take-drop.net/agreement"}>
                      https://take-drop.net/agreement
                    </Link>
                  </p>
                  <p className="terms-service__text-block-item">
                    <span>11.2.</span> Продолжение использования функций Сайта
                    после изменений означает согласие с новыми условиями. Если
                    Пользователь не согласен с изменениями, он прекращает
                    пользоваться Сайтом.
                  </p>
                  <p className="terms-service__text-block-item">
                    <sapn>11.3.</sapn> Все, что не регулируется Соглашением,
                    подчиняется законодательству Грузии.
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="terms-service__text-block">
                <h4 className="terms-service__text-block-subitem">
                  10. изменения в настоящей политике конфиденциальности
                </h4>
                <div className="terms-service__text-block-items">
                  <p className="terms-service__text-block-item">
                    Настоящим мы хотели бы сообщить вам, что мы постоянно
                    пересматриваем и обновляем настоящую Политику
                    конфиденциальности всякий раз, когда происходят существенные
                    изменения в том, как мы используем личную информацию, а
                    также для того, чтобы наша обработка информации
                    соответствовала действующему законодательству.
                  </p>
                  <p className="terms-service__text-block-item">
                    В случае внесения каких-либо изменений в Политику
                    конфиденциальности мы также можем связаться с вами, чтобы
                    уведомить вас о любых существенных изменениях, если сочтем
                    их важными и необходимыми. Настоящая Политика
                    конфиденциальности была первоначально разработана и
                    опубликована в сентябре 2021 года.
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="terms-service__text-block">
                <h4 className="terms-service__text-block-subitem">
                  11. изменения в настоящей политике конфиденциальности
                </h4>
                <div className="terms-service__text-block-items">
                  <p className="terms-service__text-block-item">
                    Если вы хотите узнать больше об общих правилах защиты
                    данных, пожалуйста, перейдите по ссылке ниже:
                  </p>
                  <p className="terms-service__text-block-item">
                    <Link
                      to={
                        "https://ico.org.uk/for-organisations/data-protection-reform/overview-of-the-gdpr/"
                      }
                    >
                      https://ico.org.uk/for-organisations/data-protection-reform/overview-of-the-gdpr/
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="terms-service__content t">
            <h2 className="terms-service__content-title ">
              ПОЛИТИКА ВОЗВРАТА СРЕДСТВ
            </h2>
            <div className="terms-service__text-content return">
              <div className="terms-service__text-block">
                <h4 className="terms-service__text-block-subitem">
                  ПОЖАЛУЙСТА, ВНИМАТЕЛЬНО ОЗНАКОМЬТЕСЬ С ЭТОЙ ПОЛИТИКОЙ ВОЗВРАТА
                  СРЕДСТВ, ПРЕЖДЕ ЧЕМ СОВЕРШАТЬ КАКИЕ-ЛИБО ПОКУПКИ ИЛИ ОБМЕНЫ на
                  take-drop.net
                </h4>
                <div className="terms-service__text-block-items p">
                  <p className="terms-service__text-block-item">
                    Этот документ является неотъемлемой частью
                    https://take-drop.net Условий использования
                    /Пользовательского соглашения, которые в совокупности
                    составляют соглашение между вами и take-drop.net
                  </p>
                  <p className="terms-service__text-block-item p">
                    Настоящая Политика возврата средств распространяется на
                    следующие покупки: Игровые предметы (скины), совместно
                    именуемые "Товары".
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="terms-service__text-block p">
                <h4 className="terms-service__text-block-subitem">
                  КОГДА Я МОГУ ВЕРНУТЬ ТОВАР?
                </h4>
                <div className="terms-service__text-block-items p">
                  <p className="terms-service__text-block-item ">
                    Наши продукты представляют собой цифровой контент
                    (виртуальные предметы), не являются товарами по смыслу
                    закона "О защите прав потребителей", и в силу своей
                    специфики на них не распространяется общее право потребителя
                    на возврат.
                  </p>
                  <p className="terms-service__text-block-item p">
                    СОГЛАШАЯСЬ С ДАННОЙ ПОЛИТИКОЙ ВОЗВРАТА И СОВЕРШАЯ ПОКУПКУ,
                    ВЫ ПОДТВЕРЖДАЕТЕ, ЧТО ВЫ ИНФОРМИРОВАНЫ О ТОМ, ЧТО У ВАС НЕТ
                    ПРАВА ВОЗВРАЩАТЬ НАШИ ТОВАРЫ, И ОТКАЗЫВАЕТЕСЬ ОТ ЛЮБЫХ
                    ПРЕТЕНЗИЙ, СВЯЗАННЫХ С ЭТИМ.
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="terms-service__text-block p">
                <h4 className="terms-service__text-block-subitem">
                  МЫ ПРИНИМАЕМ ЗАПРОСЫ НА ВОЗВРАТ ПО СЛЕДУЮЩИМ ПРИЧИНАМ:
                </h4>
                <div className="terms-service__text-block-items p">
                  <p className="terms-service__text-block-item ">
                    Товар не получен. Пожалуйста, имейте в виду, что:
                  </p>
                  <p className="terms-service__text-block-item p">
                    Мы начнем обработку вашего запроса после подтверждения
                    вашего платежа. Подтверждение платежа обычно занимает
                    несколько секунд, но может занять и до 3 дней, в зависимости
                    от способа оплаты. Вы можете проверить статус вашего платежа
                    в личном кабинете.
                  </p>
                  <p className="terms-service__text-block-item p">
                    Если вы приобрели игровой предмет, находящийся в состоянии
                    "Удержания", вы получите виртуальную копию этого игрового
                    предмета, которую сможете обменять, но возможность получить
                    игровой предмет в свой инвентарь появится только после
                    истечения срока "удержания".
                  </p>
                  <p className="terms-service__text-block-item p">
                    Если вы не получили оплаченный товар по какой-либо другой
                    причине, пожалуйста, свяжитесь со службой поддержки любым из
                    перечисленных ниже способов. Как только мы подтвердим факт
                    недоставки, мы вернем деньги.
                  </p>
                  <p className="terms-service__text-block-item p">
                    Мошеннические операции. Если покупка была совершена не вами
                    и ваши платежные данные были использованы мошенническим
                    путем, пожалуйста, свяжитесь со службой поддержки как можно
                    скорее любым из перечисленных ниже способов. Мы расследуем
                    это дело, и если деньги все еще находятся в нашей системе,
                    мы вернем их, в противном случае возврат средств невозможен.
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="terms-service__text-block p">
                <h4 className="terms-service__text-block-subitem">
                  КОГДА Я МОГУ ОЖИДАТЬ ВОЗВРАТА СРЕДСТВ?
                </h4>
                <div className="terms-service__text-block-items p">
                  <p className="terms-service__text-block-item">
                    По умолчанию деньги за покупки будут возвращены тем способом
                    оплаты, который был использован для совершения покупки. В
                    зависимости от способа оплаты возврат средств может занять
                    до 45 дней. Если ваш способ оплаты не поддерживает возврат
                    средств, мы зачислим сумму возврата на ваш баланс.
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="terms-service__text-block p">
                <h4 className="terms-service__text-block-subitem">
                  КАК С НАМИ СВЯЗАТЬСЯ?
                </h4>
                <div className="terms-service__text-block-items p">
                  <p className="terms-service__text-block-item">
                    Вы можете запросить возврат средств или получить другую
                    помощь в использовании сервиса take-drop, обратившись в нашу
                    службу поддержки по адресу info@take-drop.net. Мы приложим
                    все разумные усилия, чтобы удовлетворить ваш запрос.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

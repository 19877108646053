import {React, useEffect} from "react";

import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import "./ReturnHomeButton.scss";

export const ReturnHomeButton = ({ name, stop }) => {

  const { t } = useTranslation();

  const handleClickStopAudio = () => {
    stop();
  };

  return (
    <div className="ReturnHomeButton" onClick={handleClickStopAudio}>
      <div className="ReturnHomeItem">
        <Link to="/">{t("home")}</Link>
      </div>
      /
      <div className="ReturnHomeItem">
        <span>{name}</span>
      </div>
    </div>
  );
};

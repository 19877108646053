import React from "react";

import "../../ContentsCase/ContentsCase.scss";

import { ContentCaseItem } from "../../ContentsCase/ContentCaseItem";

export const CaseOpens = ({
  drop,
  multipliedItems,
  translateX,
  winner,
  color,
  isWin,
  isMuted,
  selectCountCase,
  caseOpening,
  sold,
}) => {
  return (
    <div className={`CaseOpens ${color}`}>
      <div className="CaseOpensRoulette">
        <div
          className="CaseOpensContent"
          style={{
            transition:
              !isWin && caseOpening === "open"
                ? "transform 5.5s cubic-bezier(0.220, 0.350, 0.310, 0.985) 0s"
                : !isWin && caseOpening === "fast"
                ? "transform 1.5s cubic-bezier(0.220, 0.350, 0.310, 0.985) 0s"
                : isWin
                ? "transform 1.2s ease"
                : "transform 0s ease",
            transform: `translate(${translateX}px)`,
          }}
        >
          {multipliedItems &&
            multipliedItems.map((obj, index) => (
              <ContentCaseItem
                {...obj}
                key={index}
                index={index}
                isWin={isWin}
              />
            ))}
        </div>
      </div>
      <div className="arrows">
        <div className={`arrowsTop ${color}`}></div>
        <div className={`arrowsBottom ${color}`}></div>
      </div>
      {selectCountCase === 1 &&
        winner &&
        drop.map((item, index) => (
          <div
            className={`winnings ${
              item.rarity === "Исключительный"
                ? "blue"
                : item.rarity === "Мастерский"
                ? " red"
                : item.rarity === "Заслуженный"
                ? "blue-army"
                : item.rarity === "Стандартное"
                ? "blue-army"
                : item.rarity === "Высшего класса"
                ? "blue"
                : item.rarity === "Примечательного типа"
                ? "blue-army"
                : item.rarity === "Экзотичного вида"
                ? " pink"
                : item.rarity === "Превосходный"
                ? "pink"
                : item.rarity === "Запрещённое"
                ? "purple"
                : item.rarity === "Промышленное качество"
                ? "blue"
                : item.rarity === "Тайное"
                ? "red"
                : item.rarity === "Ширпотреб"
                ? "green"
                : item.rarity === "Армейское качество"
                ? "blue-army"
                : item.rarity === "экстраординарного типа"
                ? "yellow"
                : item.rarity === "Контрабанда"
                ? "yellow"
                : item.rarity === "Засекреченное"
                ? "pink"
                : item.rarity === "Restricted"
                ? "purple"
                : item.rarity === "Industrial Grade"
                ? "blue"
                : item.rarity === "Covert"
                ? "red"
                : item.rarity === "Consumer Grade"
                ? "green"
                : item.rarity === "Mil-Spec"
                ? "blue-army"
                : item.rarity === "Extraordinary"
                ? "yellow"
                : item.rarity === "Contraband"
                ? "yellow"
                : item.rarity === "Classified"
                ? "pink"
                : item.rarity === "Remarkable"
                ? "blue"
                : item.rarity === "Masterpiece"
                ? " red"
                : item.rarity === "Distinguished"
                ? "blue-army"
                : item.rarity === "Standard"
                ? "blue-army"
                : item.rarity === "High Class"
                ? "blue"
                : item.rarity === "Exceptional"
                ? "blue-army"
                : item.rarity === "Exotic"
                ? " pink"
                : ""
            }`}
            key={index}
          >
            <div
              className={`CaseShadow CaseShadow-2 ${
                item.rarity === "Исключительный"
                  ? "blue"
                  : item.rarity === "Мастерский"
                  ? " red"
                  : item.rarity === "Заслуженный"
                  ? "blue-army"
                  : item.rarity === "Стандартное"
                  ? "blue-army"
                  : item.rarity === "Высшего класса"
                  ? "blue"
                  : item.rarity === "Примечательного типа"
                  ? "blue-army"
                  : item.rarity === "Экзотичного вида"
                  ? " pink"
                  : item.rarity === "Превосходный"
                  ? "pink"
                  : item.rarity === "Запрещённое"
                  ? "purple"
                  : item.rarity === "Промышленное качество"
                  ? "blue"
                  : item.rarity === "Тайное"
                  ? "red"
                  : item.rarity === "Ширпотреб"
                  ? "green"
                  : item.rarity === "Армейское качество"
                  ? "blue-army"
                  : item.rarity === "экстраординарного типа"
                  ? "yellow"
                  : item.rarity === "Контрабанда"
                  ? "yellow"
                  : item.rarity === "Засекреченное"
                  ? "pink"
                  : item.rarity === "Restricted"
                  ? "purple"
                  : item.rarity === "Industrial Grade"
                  ? "blue"
                  : item.rarity === "Covert"
                  ? "red"
                  : item.rarity === "Consumer Grade"
                  ? "green"
                  : item.rarity === "Mil-Spec"
                  ? "blue-army"
                  : item.rarity === "Extraordinary"
                  ? "yellow"
                  : item.rarity === "Contraband"
                  ? "yellow"
                  : item.rarity === "Classified"
                  ? "pink"
                  : item.rarity === "Remarkable"
                  ? "blue"
                  : item.rarity === "Masterpiece"
                  ? " red"
                  : item.rarity === "Distinguished"
                  ? "blue-army"
                  : item.rarity === "Standard"
                  ? "blue-army"
                  : item.rarity === "High Class"
                  ? "blue"
                  : item.rarity === "Exceptional"
                  ? "blue-army"
                  : item.rarity === "Exotic"
                  ? " pink"
                  : ""
              }`}
            ></div>
            <img src={item.image} alt={item.name} />
            <h3>{item.name}</h3>
          </div>
        ))}
    </div>
  );
};

import React from "react";

export const TgButton = () => {
  return (
    <button className="telegram-button">
      <span>
        <svg
          width="21"
          height="16"
          viewBox="0 0 21 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.2238 0.219814C19.8831 -0.0344259 19.348 -0.0708027 18.7947 0.124674H18.7939C18.212 0.330145 2.32268 6.32797 1.67584 6.57299C1.55819 6.60902 0.530703 6.94637 0.636542 7.69792C0.731022 8.37549 1.55683 8.65613 1.65767 8.68851L5.69722 9.90573C5.96522 10.6908 6.95316 13.5874 7.17169 14.2062C7.30794 14.592 7.53009 15.0989 7.91935 15.2032C8.26095 15.3191 8.60071 15.2132 8.82057 15.0613L11.2902 13.0454L15.2771 15.7816L15.3721 15.8316C15.6428 15.9372 15.9022 15.9899 16.1497 15.9899C16.3409 15.9899 16.5245 15.9583 16.6998 15.8952C17.2971 15.6793 17.5361 15.1784 17.561 15.1217L20.539 1.49941C20.7207 0.771865 20.4682 0.4017 20.2238 0.219814ZM9.25979 10.3926L7.89708 13.5906L6.53437 9.59309L16.9819 2.7974L9.25979 10.3926Z"
            fill="white"
          />
        </svg>
      </span>
      Telegram
    </button>
  );
};

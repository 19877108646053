import vk from "./vk.png";
import instagram from "./instagram.svg";
import telegram from "./telegram.svg";
import wallet from "./wallet.png";
import user from "./user.png";
import user2 from "./user-solid.png";
import gun from "./gun.png";
import winnersBlockkrown from "./winnersBlockkrown.png";
import userAvatar from "./userAvatar.png";
import smoke from "./smoke.png";
import arrowRight from "./arrow-right.png";
import case_1 from "./case.png";
import caseIco from "./case-ico.png";
import documentIco from "./document-ico.png";
import upArrow from "./up-arrow.png";
import users from "./users.png";
import qiwi from "./qiwi.png";
import masterCard from "./masterCard.png";
import visa from "./visa.png";
import webMoney from "./webMoney.png";
import close from "./close.png";
import Layer_777 from "./Layer 777.png";
import copy_userId from "./copy-userId.svg";
import steam from "./steam.svg";
import update from "./update.svg";
import freeCase from "./free_case.png";
import case_open_arrow_top from "./case_open_arrow_top.png";
import case_open_arrow_bottom from "./case_open_arrow_bottom.png";
import winnings from "./winnings.png";
import scroll_top from "./scroll_top.png";
import replenishSvg from "./replenishSvg.svg";
import steamSvg from "./steamSvg.svg";
import live from "./live.svg";
import top from "./top.svg";
import meansOfPayment from "./Платежные средства.svg";
import gunLoading from "./gun.svg";
// LANGUAGES
import langRu from "./langRu.svg";
import langEn from "./LangEn.svg";

// HEADER ICONS
import keys from "./keys.svg";
import upgrades from "./upgrades.svg";
import contracts from "./contracts.png";


// LOGO
import headerLogo from "./header-logo.svg";
import logo from "./logo.svg";

import email from "./email.svg";
import chat from "./chat.svg";

import wallet_icon from "./wallet.svg";

// footer
import payments from "./payments.svg";

import termsLogo from "./terms.logo.svg";

import minus from "./faq_minus.svg";
import plus from "./faq_plus.svg";

// work
import work from "./work.png";

const link_img = {
  vk: vk,
  instagram: instagram,
  telegram: telegram,
  wallet: wallet,
  user: user,
  user2: user2,
  gun: gun,
  winnersBlockkrown: winnersBlockkrown,
  userAvatar: userAvatar,
  smoke: smoke,
  arrowRight: arrowRight,
  case_1: case_1,
  caseIco: caseIco,
  documentIco: documentIco,
  upArrow: upArrow,
  users: users,
  qiwi: qiwi,
  masterCard: masterCard,
  webMoney: webMoney,
  visa: visa,
  close: close,
  Layer_777: Layer_777,
  copy_userId: copy_userId,
  steam: steam,
  update: update,
  freeCase: freeCase,
  case_open_arrow_top: case_open_arrow_top,
  case_open_arrow_bottom: case_open_arrow_bottom,
  winnings: winnings,
  scroll_top: scroll_top,
  replenishSvg: replenishSvg,
  steamSvg: steamSvg,
  live: live,
  top: top,
  meansOfPayment: meansOfPayment,
  gunLoading: gunLoading,

  langRu: langRu,
  langEn: langEn,

  // header icons
  keys:keys,
  upgrades:upgrades,
  contracts: contracts,

  headerLogo: headerLogo,
  logo: logo,

  email: email,
  chat: chat,

  wallet_icon: wallet_icon,

  // payments
  payments: payments,
  termsLogo: termsLogo,

  minus: minus,
  plus: plus,

  // work
  work: work,
};

export default link_img;

import React from "react";

export const Item = ({ img, name, surname, rarity, color, price, members }) => {
  return (
    <div className={`giveaway__popup-item`}>
      <div className="popup__item-inner">
        <div className={`popup__item-img ${color}`}>
          <img src={img} alt={name} />
          <div className="popup__item-name">
          <p className="name">{name}</p>
          <p className="name surname">{surname}</p>
          <p className={`rarity popup__item-rarity ${color}`}>{rarity}</p>
        </div>
        <div className={`popup__item-img-after ${color}`}></div>
        </div>
        <div className="popup__item-attrs">
        <div className={`popup__item-price ${color}`}>{price} ₽</div>
        <div className={`popup__item-members `}>Участники: {members}</div>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import link_img from "../../img/link_img";

import { useTranslation } from "react-i18next";

import { TotalCases } from "../../components/TotalCases/TotalCases"


import { useFetchSteamLoginUrlMutation } from "../../redux/cases/cases";
import { Link } from "react-router-dom";

const languages = [
  { lang: "Русский", icon: link_img.langRu, id: "ru" },
  { lang: "English", icon: link_img.langEn, id: "en" },
];

export const HeaderTop = ({
  refetchUserData,
  error,
  userData,
  setLogin,
  login,
  isMuted,
  setMuted,
  isLoading,
  setPayments,
}) => {
  // REGISTER STEAM
  const isAuth = document.cookie
    ?.split("; ")
    .find((row) => row?.startsWith("access_token="));

  useEffect(() => {
    setTimeout(() => {
      refetchUserData();
    }, 500);
  }, [isAuth]);

  useEffect(() => {
    localStorage.setItem("muted", isMuted);
  }, [isMuted]);

  if (error?.data?.error) {
    document.cookie = "access_token=; Max-Age=0; path=/;";
  }

  const [fetchSteamLoginUrl] = useFetchSteamLoginUrlMutation();
  const [steamLoginErr, setSteamLoginErr] = useState();

  // LANGUAGES
  const [isLangMenu, setLangMenu] = useState(false);
  const [selectLanguages, setSelectLanguages] = useState("ru");

  const handleSteamLogin = async () => {
    try {
      const { data } = await fetchSteamLoginUrl({ type: "steam" });
      window.location.href = data.data.redirect_url;

      setLogin(false);
    } catch (error) {
      setSteamLoginErr("Неудалось получить ссылку");
    }
  };

  useEffect(() => {
    if (login === true) {
      handleSteamLogin();
    }
  }, [login]);

  // LANGUAGE
  const { t, i18n } = useTranslation();

  const changeLanguage = (id) => {
    setSelectLanguages(id);
    localStorage.setItem("i18nextLng", id);
    i18n.changeLanguage(id);

    window.location.reload();

    setLangMenu(false);
  };

  return (
    <div className="HeaderTop">
      <div className="inner">
        <ul className="HeaderStats">
          <li>
            <div className="HeaderStatsCoverItem">
            <Link to={'/'} className="">
            <img src={link_img.keys} alt="keys" />
            <div className="stats_values"><p><TotalCases /></p><span>Кейсы</span></div>
            </Link>
            </div>
          </li>
          <li>
          <div className="HeaderStatsCoverItem">
          <Link to={'/'} className="">
          <img src={link_img.upgrades} alt="upgrades" />
            <div className="stats_values"><p>1233999</p><span>Апгрейды</span></div>
            </Link>
            </div>
          </li>
          <li>
          <div className="HeaderStatsCoverItem">
          <Link to={'/'} className="">
          <img src={link_img.contracts} alt="contracts" />
            <div className="stats_values"><p>1233999</p><span>Контракты</span></div>
            </Link>
            </div>
          </li>
        </ul>
        <Link to={"/"} className="header_logo">
          <img src={link_img.logo} className="header_logo-logo" alt="logo" />
          {/* <img
            src={link_img.headerLogo}
            className="header_logo-name"
            alt="logo"
          /> */}
        </Link>
       
        <div className="HeaderRightLine">
          <svg
            width="277"
            height="65"
            viewBox="0 0 277 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.6"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 64.9999H0L45 0H49L4 64.9999ZM12 64.9999H8L53 0H57L12 64.9999ZM20 65H16L61 0H65L20 65ZM28 65L24 64.9999L69 0H73L28 65ZM36 64.9999L32 65L77 0H81L36 64.9999ZM44 65H40L85 0H89L44 65ZM52 65H48L93 0H97L52 65ZM60 65H56L101 0H105L60 65ZM68 65H64L109 0H113L68 65ZM76 65H72L117 0H121L76 65ZM84 65H80L125 0H129L84 65ZM92 65H88L133 0H137L92 65ZM100 65L96 64.9999L141 0H145L100 65ZM108 64.9999H104L149 0H153L108 64.9999ZM116 65H112L157 0H161L116 65ZM124 65L120 64.9999L165 0H169L124 65ZM132 65H128L173 0H177L132 65ZM140 65H136L181 0H185L140 65ZM148 64.9999L144 65L189 0H193L148 64.9999ZM156 65L152 64.9999L197 0H201L156 65ZM164 65L160 64.9999L205 0H209L164 65ZM172 64.9999H168L213 0H217L172 64.9999ZM180 64.9999L176 65L221 0H225L180 64.9999ZM188 64.9999L184 65L229 0H233L188 64.9999ZM196 65L192 64.9999L237 0H241L196 65ZM204 65H200L245 0H249L204 65ZM212 65H208L253 0H257L212 65ZM220 65L216 64.9999L261 0H265L220 65ZM228 64.9999H224L269 0H273L228 64.9999ZM236 65H232L277 0H281L236 65ZM244 64.9999L240 65L285 0H289L244 64.9999ZM252 65H248L293 0H297L252 65ZM260 64.9999L256 65L301 0H305L260 64.9999ZM268 65H264L309 0H313L268 65ZM276 65H272L317 0H321L276 65ZM284 64.9999H280L325 0H329L284 64.9999ZM292 65H288L333 0H337L292 65ZM300 65H296L341 0H345L300 65ZM308 65H304L349 0H353L308 65ZM316 65L312 64.9999L357 0H361L316 65ZM324 65H320L365 0H369L324 65ZM332 65L328 64.9999L373 0H377L332 65ZM340 65H336L381 0H385L340 65Z"
              fill="url(#paint0_linear_1_18)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1_18"
                x1="-17"
                y1="-52.5"
                x2="481.658"
                y2="244.519"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.200379" stopColor="#200038" />
                <stop offset="0.485189" stopColor="#CD1FC6" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <div className="wrapper">
          {isAuth ? (
            isLoading ? (
              <button className="replenishBtn balance loadingBtn">
                <span>
                  <img src={link_img.replenishSvg} alt="" />
                </span>
                {t("replenish")}
              </button>
            ) : userData?.balance === "0.00" ? (
              <button
                className="replenishBtn"
                onClick={() => setPayments(true)}
              >
                <span>
                  <img src={link_img.replenishSvg} alt="" />
                </span>
                {t("replenish")}
              </button>
            ) : (
              <>
                <button
                  className="replenishBtn balance"
                  onClick={() => setPayments(true)}
                >
                  <span>
                    <img src={link_img.replenishSvg} alt="" />
                  </span>
                  {userData?.balance} руб.
                </button>
              </>
            )
          ) : (
            ""
          )}

          <div className="buttonsRight">
            {isAuth ? (
              isLoading ? (
                <div className="AvatarLoading">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11.9648 13.4769C15.5191 13.4769 18.3695 10.4492 18.3695 6.75692C18.3695 3.06462 15.5191 0 11.9648 0C8.41056 0 5.56012 3.02769 5.56012 6.72C5.56012 10.4123 8.41056 13.4769 11.9648 13.4769ZM11.9648 1.77231C14.5689 1.77231 16.6804 3.98769 16.6804 6.72C16.6804 9.45231 14.5689 11.6677 11.9648 11.6677C9.3607 11.6677 7.24927 9.48923 7.24927 6.75692C7.24927 4.02462 9.3607 1.77231 11.9648 1.77231ZM0.844575 24H23.1554C23.6129 24 24 23.5938 24 23.1138C24 18.4615 20.3754 14.6585 15.9414 14.6585H8.05865C3.62463 14.6585 0 18.4615 0 23.1138C0 23.5938 0.387097 24 0.844575 24ZM8.05865 16.4308H15.9414C19.1789 16.4308 21.8182 18.9415 22.2405 22.2277H1.75953C2.18182 18.9415 4.82111 16.4308 8.05865 16.4308Z"
                      fill="#4E127D"
                    />
                  </svg>
                </div>
              ) : (
                <Link to="/profile" className="ProfileImg">
                  <img src={userData?.avatar_img} alt="" />
                </Link>
              )
            ) : (
              <button className="loginBtn" onClick={handleSteamLogin}>
                <span>
                  <img src={link_img.steamSvg} alt="" />
                </span>
                {t("login")}
              </button>
            )}

            <div className="languages">
              <div
                className="select_languages"
                onClick={() => setLangMenu(!isLangMenu)}
              >
                {i18n.language === "ru" ? (
                  <img src={link_img.langRu} alt="ru" />
                ) : (
                  <img src={link_img.langEn} alt="en" />
                )}
              </div>
              <div className={`languages_list ${isLangMenu && "open"}`}>
                <div className="languages_list-inner">
                  {languages.map((item) => (
                    <div
                      className={`languages_list-item ${
                        item.id === i18n.language ? "active" : ""
                      }`}
                      onClick={() => changeLanguage(item.id)}
                      key={item.id}
                    >
                      <img src={item.icon} alt={item.id} />
                      <span>{item.lang}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <button className="sound" onClick={() => setMuted(!isMuted)}>
              {isMuted ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="17"
                  viewBox="0 0 22 17"
                  fill="none"
                >
                  <path
                    d="M9.23948 0.302748L5.41659 4.12478H1.03124C0.46148 4.12478 0 4.58626 0 5.15602V11.3435C0 11.9128 0.46148 12.3747 1.03124 12.3747H5.41659L9.23948 16.1967C9.88529 16.8425 10.9999 16.3888 10.9999 15.4676V1.03192C10.9999 0.10982 9.88443 -0.342206 9.23948 0.302748ZM19.8359 8.24974L21.797 6.28867C22.0677 6.01797 22.0677 5.57883 21.797 5.30813L20.8164 4.32759C20.5457 4.05689 20.1066 4.05689 19.8359 4.32759L17.8748 6.28867L15.9137 4.32759C15.643 4.05689 15.2039 4.05689 14.9332 4.32759L13.9527 5.30813C13.682 5.57883 13.682 6.01797 13.9527 6.28867L15.9137 8.24974L13.9531 10.2104C13.6824 10.4811 13.6824 10.9202 13.9531 11.1909L14.9336 12.1715C15.2043 12.4422 15.6435 12.4422 15.9142 12.1715L17.8748 10.2108L19.8359 12.1719C20.1066 12.4426 20.5457 12.4426 20.8164 12.1719L21.797 11.1914C22.0677 10.9207 22.0677 10.4815 21.797 10.2108L19.8359 8.24974Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="21"
                  viewBox="0 0 25 21"
                  fill="none"
                >
                  <path
                    d="M9.3329 2.40853L5.47135 6.29991H1.04167C0.466146 6.29991 0 6.76976 0 7.34986V13.6496C0 14.2292 0.466146 14.6995 1.04167 14.6995H5.47135L9.3329 18.5909C9.98524 19.2484 11.1111 18.7864 11.1111 17.8485V3.15093C11.1111 2.2121 9.98437 1.75187 9.3329 2.40853ZM19.4596 0.173882C18.9748 -0.14679 18.3233 -0.0116092 18.0052 0.47793C17.6866 0.966595 17.822 1.62325 18.3069 1.94392C21.1832 3.84652 22.8997 7.04493 22.8997 10.5001C22.8997 13.9554 21.1832 17.1538 18.3069 19.0564C17.822 19.3766 17.6866 20.0337 18.0052 20.5219C18.3108 20.9905 18.957 21.1589 19.4596 20.826C22.9284 18.531 25 14.6702 25 10.4997C25 6.32922 22.9284 2.4689 19.4596 0.173882ZM20.8333 10.4997C20.8333 7.7204 19.4418 5.16508 17.1107 3.66453C16.625 3.35217 15.9809 3.49741 15.6732 3.99089C15.3655 4.48437 15.5091 5.13752 15.9948 5.45032C17.7201 6.56108 18.75 8.44837 18.75 10.4997C18.75 12.5511 17.7201 14.4383 15.9948 15.5491C15.5091 15.8615 15.3655 16.5146 15.6732 17.0085C15.9557 17.4618 16.5898 17.6709 17.1107 17.3349C19.4418 15.8343 20.8333 13.2795 20.8333 10.4997ZM14.6801 7.13681C14.1775 6.85988 13.5434 7.04231 13.2648 7.55022C12.9874 8.05814 13.171 8.69642 13.6749 8.97684C14.2352 9.28702 14.5833 9.87105 14.5833 10.4997C14.5833 11.1288 14.2352 11.7124 13.6753 12.0226C13.1714 12.303 12.9878 12.9413 13.2652 13.4492C13.5443 13.9593 14.1788 14.1404 14.6806 13.8626C15.9058 13.1823 16.6671 11.894 16.6671 10.4993C16.6671 9.10459 15.9058 7.81665 14.6801 7.13681Z"
                    fill="white"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

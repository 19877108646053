import React, { useState, useEffect } from "react";

import "../components/Profile/Profile.scss";
import { ProfileInfo } from "../components/Profile/ProfileInfo/ProfileInfo";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import {
  useGetUserInfoItemsQuery,
  useGetUserInfoQuery,
} from "../redux/cases/cases";
import { Pagination } from "../components/Profile/Pagination/Pagination";
import { Loading } from "../components/Loading/Loading";
import { UserItems } from "../components/UserItems/UserItems";
import { UserSortInventory } from "../components/UserSort/UserSortInventory";
import { EscapeToHome } from "../components/EscapeToHome/EscapeToHome";

export const User = () => {
  const { user_id } = useParams();
  const { t } = useTranslation();

  const [userData, setUserData] = useState([]);

  const {
    data: userInfo,
    isSuccess: userDataSuccess,
    isLoading: userDataLoading,
  } = useGetUserInfoQuery(user_id);

  useEffect(() => {
    if (user_id) {
      if (userDataSuccess) setUserData(userInfo);
    }
  }, [user_id, userData, userDataSuccess, userInfo]);

  // !!
  const { start_price, end_price, page } = useSelector(
    (state) => state.filterCase || {}
  );

  const { data, isLoading: userItemsLoading } = useGetUserInfoItemsQuery({
    user_id: user_id,
    start_price,
    end_price,
    page,
  });

  useEffect(() => {
    console.log("ОБНОВЛЕНИЕ: ", start_price, end_price, page);
    // refetchUserItems({})
  }, [start_price, end_price, page]);
  // !!

  if (userDataLoading || userItemsLoading) {
    return <Loading />;
  }

  return (
    <div className="Profile">
      <div className="main mainWidht">
        <div className="home-btn">
          <Link to="/" className="ToHome">
            <span>{t("toHome")}</span>
          </Link>
           {/* this component only return to home page! */}
        <EscapeToHome />
        </div>
        <ProfileInfo userData={userData} />
        {start_price !== null &&
        end_price !== null &&
        data?.data?.items.length === 0 ? (
          <>
            <UserSortInventory />
            <div className="ProfileNoItems">
              {t("noUserItems")} {start_price}₽ {t("noUserItemsTo")} {end_price}
              ₽
            </div>
          </>
        ) : data?.data?.items.length > 0 ? (
          <>
            <UserSortInventory />
            <div className="ProfileBottom">
              {data?.data?.items.map((obj, index) => (
                <UserItems
                  rarity={obj.rarity}
                  image={obj.image}
                  name={obj.name}
                  key={index}
                />
              ))}
            </div>
            {data?.data?.page_count > 1 && (
              <Pagination pageCount={data?.data?.page_count} page={page} />
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

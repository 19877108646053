import React, { useState, useEffect } from "react";

import "../components/Profile/Profile.scss";
import { ToHome } from "../components/ToHome/ToHome";
import { EscapeToHome } from "../components/EscapeToHome/EscapeToHome";
import { ProfileInfo } from "../components/Profile/ProfileInfo/ProfileInfo";
import { SkinsOutput } from "../components/Profile/SkinsOutput/SkinsOutput";
import { SortInventory } from "../components/Profile/SortInventory/SortInventory";
import { ProfileBottom } from "../components/Profile/ProfileBottom/ProfileBottom";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import {
  useGetUserItemsQuery,
  useGetUserQuery,
  useLazyGetAllItemSaleQuery,
  useLazyGetItemSaleQuery,
  useLazyGetWithdrawalItemQuery,
} from "../redux/cases/cases";
import { Pagination } from "../components/Profile/Pagination/Pagination";
import { Notification } from "../components/Notification/Notification";
import { Loading } from "../components/Loading/Loading";
import { Navigate } from "react-router-dom";

export const Profile = () => {
  const { t } = useTranslation();

  const { start_price, end_price, page } = useSelector(
    (state) => state.filterCase || {}
  );
  const { refetch: refetchUserItems } = useGetUserItemsQuery({
    start_price,
    end_price,
    page,
  });
  const { refetch: refetchUserData } = useGetUserQuery(null);

  const [openSaleMenu, setOpenSaleMenu] = useState(false);
  const [openSaleAllItemMenu, setOpenSaleAllItemMenu] = useState(false);
  const [openWithdrawalItemMenu, setOpenWithdrawalItemMenu] = useState(false);

  const [itemId, setItemId] = useState();
  const [withdrawalId, setWithdrawalId] = useState();

  const [itemPrice, setItemPrice] = useState();
  const [saleItems, setSaleItems] = useState("");
  const [itemWithdrawal, setItemWithdrawal] = useState("");

  const [isSaleItems, setIsSaleItems] = useState(false);
  const [allSaleItems, setAllSaleItems] = useState(false);

  const { data, isLoading: userItemsLoading } = useGetUserItemsQuery({
    start_price,
    end_price,
    page,
  });
  const [sale] = useLazyGetItemSaleQuery();
  const [allSale] = useLazyGetAllItemSaleQuery();

  const { data: userData, isLoading } = useGetUserQuery(null);

  const [withdrawal] = useLazyGetWithdrawalItemQuery();

  const [showNotification, setShowNotification] = useState(false);

  const handleWithdrawalItem = async () => {
    const { data: withdrawalMessage, error: withdrawalMessageError } =
      await withdrawal(withdrawalId);

    setOpenWithdrawalItemMenu(false);

    refetchUserItems({ start_price, end_price, page });
    refetchUserData();

    setShowNotification(true);

    setItemWithdrawal(
      withdrawalMessage?.message || withdrawalMessageError?.data?.error
    );

    setTimeout(() => {
      setShowNotification(false);
      setItemWithdrawal("");
    }, 3350);
  };

  const handleSaleItem = async () => {
    await sale(itemId);
    refetchUserItems({ start_price, end_price, page });
    refetchUserData();
    setOpenSaleMenu(false);

    setShowNotification(true);

    setTimeout(() => {
      setShowNotification(false);
    }, 3350);
  };

  const handleAllSaleItem = async () => {
    const { isSuccess, data: allSaleData } = await allSale();

    if (isSuccess) {
      refetchUserItems({ start_price, end_price, page });
      refetchUserData();

      setIsSaleItems(true);
      setShowNotification(true);

      setSaleItems(allSaleData?.message);
    }

    setOpenSaleAllItemMenu(false);

    setTimeout(() => {
      setIsSaleItems(false);
      setShowNotification(false);
    }, 3350);
  };

  const isAuth = document.cookie
    ?.split("; ")
    .find((row) => row?.startsWith("access_token="));

  if (!isAuth) {
    return <Navigate to={"/"} />;
  }
  if (isLoading || userItemsLoading) {
    return <Loading />;
  }

  return (
    <div className="Profile">
      <div className="main mainWidht">
        <ToHome />
         {/* this component only return to home page! */}
         <EscapeToHome />
        <ProfileInfo userData={userData} />
        <SkinsOutput
          cls={data?.data?.items}
          start_price={start_price}
          end_price={end_price}
        />
        {start_price !== null &&
        end_price !== null &&
        data?.data?.items.length === 0 ? (
          <>
            <SortInventory
              setOpenSaleAllItemMenu={setOpenSaleAllItemMenu}
              setAllSaleItems={setAllSaleItems}
            />
            <div className="ProfileNoItems">
              {t("noItems")} {start_price}₽ {t("from")} {end_price}₽ {t("to")}
            </div>
          </>
        ) : data?.data?.items.length > 0 ? (
          <>
            <SortInventory
              setOpenSaleAllItemMenu={setOpenSaleAllItemMenu}
              setAllSaleItems={setAllSaleItems}
            />
            <div className="ProfileBottom">
              {data?.data?.items.map((obj, index) => (
                <ProfileBottom
                  key={index}
                  image={obj.image}
                  id={obj.id}
                  name={obj.name}
                  price={obj.price}
                  rarity={obj.rarity}
                  status={obj.status}
                  sale_allowed={obj.sale_allowed}
                  withdrawn_allowed={obj.withdrawn_allowed}
                  setOpenSaleMenu={setOpenSaleMenu}
                  setItemId={setItemId}
                  setItemPrice={setItemPrice}
                  setWithdrawalId={setWithdrawalId}
                  setOpenWithdrawalItemMenu={setOpenWithdrawalItemMenu}
                />
              ))}
            </div>
            {data?.data?.page_count > 1 && (
              <Pagination pageCount={data?.data?.page_count} page={page} />
            )}
          </>
        ) : (
          ""
        )}
        {openSaleMenu && (
          <div className="saleMenu" onClick={() => setOpenSaleMenu(false)}>
            <div className="saleMenuBlock" onClick={(e) => e.stopPropagation()}>
              <h3>{t("skinSale")}</h3>
              <p>
                {t("sellThisItem")}
                <span> {itemPrice} ₽</span>?
              </p>
              <div className="saleMenuButtons">
                <button className="ok" onClick={() => handleSaleItem(itemId)}>
                  Ок
                </button>
                <button
                  className="cancel"
                  onClick={() => setOpenSaleMenu(false)}
                >
                  {t("cancel")}
                </button>
              </div>
            </div>
          </div>
        )}
        {openSaleAllItemMenu && (
          <div
            className="saleMenu"
            onClick={() => setOpenSaleAllItemMenu(false)}
          >
            <div className="saleMenuBlock" onClick={(e) => e.stopPropagation()}>
              <h3>{t("skinSale")}</h3>
              <p>{t("sellAllItems")} </p>
              <div className="saleMenuButtons">
                <button className="ok" onClick={() => handleAllSaleItem()}>
                  Ок
                </button>
                <button
                  className="cancel"
                  onClick={() => setOpenSaleAllItemMenu(false)}
                >
                  {t("cancel")}
                </button>
              </div>
            </div>
          </div>
        )}

        {openWithdrawalItemMenu && (
          <div
            className="saleMenu"
            onClick={() => setOpenWithdrawalItemMenu(false)}
          >
            <div className="saleMenuBlock" onClick={(e) => e.stopPropagation()}>
              <h3>{t("confirmationSkinWithdrawal")}</h3>
              <p>{t("sureItem")}</p>
              <div className="saleMenuButtons">
                <button className="ok" onClick={() => handleWithdrawalItem()}>
                  Ок
                </button>
                <button
                  className="cancel"
                  onClick={() => setOpenWithdrawalItemMenu(false)}
                >
                  {t("cancel")}
                </button>
              </div>
            </div>
          </div>
        )}

        <Notification
          price={itemPrice}
          showNotification={showNotification}
          saleItems={saleItems}
          isSaleItems={isSaleItems}
          itemWithdrawal={itemWithdrawal}
        />
      </div>
    </div>
  );
};

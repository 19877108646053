import React from "react";
import { useTranslation } from "react-i18next";

export const MethodItem = ({
  id,
  name,
  min_sum,
  max_sum,
  images,
  selectPay,
  setSelectPay,
  selectedPay,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`payments__item ${selectPay === id ? "selected" : ""}`}
      onClick={() => {
        setSelectPay(id);
        selectedPay(min_sum, max_sum);
      }}
    >
      <div className="payments__item-inner">
        <div className="payments__item-image">
          <img src={images} alt={name} />
        </div>
        <div className="payments__item-description">
          <span>{name}</span>
          <span className="payments__item-description_min">
            {t("min")}. {min_sum}₽
          </span>
        </div>
      </div>
    </div>
  );
};

import React from "react";

import { useTranslation } from "react-i18next";

export const Notif = ({ showNotification, payErr }) => {
  const { t } = useTranslation();
  console.log(showNotification);

  return (
    <div className={`notification ${showNotification ? "open" : ""}`}>
      <div className="notificationInner">
        <div className="notificationHead">{t("error")}</div>
        <p className="notificationText">{payErr}</p>
        <div className="notificationLine"></div>
      </div>
    </div>
  );
};

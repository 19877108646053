import React from "react";
import { useTranslation } from "react-i18next";

export const GiveawayItem = ({
  name,
  image,
  itemName,
  rarity,
  day,
  hour,
  minutes,
  seconds,
  price,
  participants,
  color,
  handleClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className="giveaway__card">
      <div className="giveaway__card-inner">
        <div className={`giveaway__card-image ${color}`} onClick={handleClick}>
          <img src={image} alt={itemName} />
          <div className="giveaway__card-names">
          <div className={`giveaway__card-item_name ${color}`}>
          <p className={`item-name ${color}`}>{itemName}</p>
          <p className={`item-rarity ${color}`}>{rarity}</p>
        </div>
          </div>
          <div className={`giveaway__card-after ${color}`}></div>
        </div>
        <div className="giveaway__card-name">{name}</div>
        <div className="giveaway__card-time">
          <div className="giveaway__time-item">
            <div className="giveaway__item-time">{day}</div>
            <span>{t("days")}</span>
          </div>
          <div className="giveaway__time-item">
            <div className="giveaway__item-time">{hour}</div>
            <span>{t("hour")}</span>
          </div>
          <div className="giveaway__time-item">
            <div className="giveaway__item-time">{minutes}</div>
            <span>{t("minutes")}</span>
          </div>
          <div className="giveaway__time-item">
            <div className="giveaway__item-time">{seconds}</div>
            <span>{t("sec")}</span>
          </div>
        </div>
        <div className="giveaway__card-bottom">
          <div className={`giveaway__card-price ${color}`}>{price}</div>
          <div className="giveaway__card-participants">
            Участники: {participants}
          </div>
        </div>
      </div>
    </div>
  );
};

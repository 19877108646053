import React, { useState } from "react";

import "./SortInventory.scss";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  setMaxPrice,
  setMinPrice,
  setPage,
} from "../../../redux/slices/filterCase";

const priceList = [
  { min: "0", max: "9", name: "0-9", id: 1 },
  { min: "10", max: "99", name: "10-99", id: 2 },
  { min: "100", max: "499", name: "100-499", id: 3 },
  { min: "500", max: "999", name: "500-999", id: 4 },
  { min: "1000", max: "9999999", name: "1000+", id: 5 },
];

export const SortInventory = ({ setOpenSaleAllItemMenu, setAllSaleItems }) => {
  const { t } = useTranslation();

  const category = [`${t("cases")}`];

  const [selectCategory, setSelectCategory] = useState(0);
  const [selectedCategoryPrice, setSelectedCategoryPrice] = useState(null);

  const dispatch = useDispatch();

  // filter case
  const handleFilterPrice = (min, max) => {
    dispatch(setMinPrice(min));
    dispatch(setMaxPrice(max));
    dispatch(setPage(1));
  };

  const handleAllSaleItem = async () => {
    setOpenSaleAllItemMenu(true);
    setAllSaleItems(true);
  };

  return (
    <div className="SortInventory">
      <div className="SortInventoryCategory">
        <ul className="categoryList">
          {category.map((item, index) => (
            <li
              className={
                selectCategory === index
                  ? "categoryItem active"
                  : "categoryItem"
              }
              key={index}
              onClick={() => setSelectCategory(index)}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
      <button className="SellEverything" onClick={() => handleAllSaleItem()}>
        {t("sellAll")}
      </button>
      <div className="SortInventoryPrice">
        <ul className="priceList">
          {priceList.map((item, index) => (
            <li
              className={
                selectedCategoryPrice === index
                  ? "priceItem active"
                  : "priceItem"
              }
              key={index}
              onClick={() => {
                setSelectedCategoryPrice(index);
                handleFilterPrice(item.min, item.max);
              }}
            >
              {item.name}₽
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

import React from "react";
import { Item } from "./Item";
import { TgButton } from "../components/TgButton";
import { ButtonReplenish } from "../components/ButtonReplenish";

import "./giveawayPopup.scss";
import { useTranslation } from "react-i18next";

export const GiveawayPopup = ({ setIsOpen, setPayments }) => {
  const { t } = useTranslation();

  return (
    <div className="giveaway__popup" onClick={() => setIsOpen(false)}>
      <div
        className="giveaway__popup-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="giveaway__popup-inner">
          <div className="giveaway__popup-awards">
            <Item
              img={
                "https://storage.take-drop.net/images/items/skins/weapon_deagle/so_night/65f614e24002c.png"
              }
              name={"Five-Seven "}
              surname={"Скоростной зверь"}
              rarity={"Необычайно редкое"}
              price={1000}
              color={"red"}
              members={122}
            />
            <Item
              img={
                "https://storage.take-drop.net/images/items/skins/weapon_awp/cu_medieval_dragon_awp/65f615470ab5b.png"
              }
              name={"AWP"}
              surname={"Горячечные грёзы"}
              rarity={"Необычайно редкое"}
              price={1500}
              color={"pink"}
              members={12}
            />
            <Item
              img={
                "https://storage.take-drop.net/images/items/skins/weapon_ak47/cu_well_traveled_ak47/65f615338a30a.png"
              }
              name={"Dual Berettas"}
              surname={"Удар кобры"}
              rarity={"Необычайно редкое"}
              price={2000}
              color={"pink"}
              members={122}
            />
          </div>
          <div className="giveaway__popup-conditions">
            <div className="popup__conditions-inner">
              <div className="popup__conditions-item task">
                <div className="item">{t("participation")}</div>
                <div class="ellipse-w"></div>
                <div className="item">Пополнить баланс на сумму 1000₽</div>
                <div class="ellipse-w"></div>
                <div className="item">Подписаться на наш Telegram канал</div>
              </div>
              <div class="ellipse one"></div>
              <div className="popup__conditions-item completed">
                <div className="item item-block">{t("replenished")}: 450₽</div>
                <div className="item item-block">{t("replenish")}: 550₽</div>
                <ButtonReplenish
                  handleClick={() => {
                    setIsOpen(false);
                    setPayments(true);
                  }}
                />
              </div>
              <div class="ellipse two"></div>
              <div className="popup__conditions-item item-btn">
                <TgButton />
              </div>
            </div>
          </div>

          <button className="popup-participate">{t("participate")}</button>
        </div>
      </div>
    </div>
  );
};

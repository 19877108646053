import React from "react";
import { useTranslation } from "react-i18next";

export const ItemLoading = () => {
  const { t } = useTranslation();

  return (
    <div className="payments__item">
      <div className="payments__item-inner">
        <div className="payments__item-image">{t("loading")}...</div>
        <div className="payments__item-description">
          <span>{t("through")}...</span>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect } from "react";
import "./AllCases.scss";
import { Link } from "react-router-dom";
import { Loading } from "../Loading/Loading";
import { useDispatch } from "react-redux";
import { setSelectedCategory } from "../../redux/slices/categoriesSlice";
export const AllCases = ({
  categoryRefs,
  categoryCases,
  isLoading,
  scrollPosition,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    categoryRefs.current = {};
    categoryCases?.data.cases?.forEach((item) => {
      categoryRefs.current[item.category_name] = React.createRef();
    });
  }, [categoryCases]);

  useEffect(() => {
    categoryRefs.current = {};
    categoryCases?.data.cases?.forEach((item) => {
      categoryRefs.current[item.category_name] = React.createRef();
    });

    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      for (const [key, value] of Object.entries(categoryRefs.current)) {
        if (value.current && value.current.offsetTop <= scrollPosition + 250) {
          dispatch(setSelectedCategory(key));
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [categoryCases]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {categoryCases?.data.cases?.map((item) => (
        <div
          className="CasesAll"
          key={item.category_name}
          ref={categoryRefs.current[item.category_name]}
        >
          <h2>{item.category_name}</h2>
          <div className="CasesAllMain mainWidht">
            {item.items.map((subItem, index) => (
              <Link
                to={`/case/${subItem.url}`}
                key={index}
                onClick={() =>
                  localStorage.setItem("scrollPosition", scrollPosition)
                }
              >
                <div className={`CaseCard ${subItem.color}`} key={subItem.url}>
                  <div className={`CaseCardWrapper ${subItem.color}`}>
                    <div className={`CaseCardInner ${subItem.color}`}>
                      <div className="price">
                        <span>{subItem.price}</span> ₽
                      </div>
                      <img src={subItem.image} alt={subItem.url} />
                      <p>{subItem.name}</p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

import React, { useState, useEffect } from "react";

import "./faq.scss";
import { ButtonHome } from "../../components/ButtonHome/ButtonHome";
import { EscapeToHome } from "../../components/EscapeToHome/EscapeToHome";
import link_img from "../../img/link_img";

export const faqCategory = [
  { name: "Пополнение", type: "payments" },
  { name: "Кейсы", type: "cases" },
  { name: "Профиль", type: "profile" },
  { name: "Предметы", type: "items" },
  { name: "Сотрудничество", type: "cooperation" },
];

export const contentCategory = [
  {
    type: "payments",
    data: [
      {
        title: "Как пополнить баланс?",
        description: [
          {
            text_1:
              "Для перехода на страницу пополнения баланса нажмите на кнопку пополнения баланса в правом верхнем углу после авторизации на сайте.",
            text_2:
              "На странице оплаты выберите удобный для вас способ оплаты.",
          },
        ],
      },
      {
        title: "Не пришли средства после пополнения баланса?",
        description: [
          {
            text_1:
              "Обычно средства на баланс поступают в течение нескольких минут, но иногда из-за внешних сбоев платежной системы, это может занять немного больше времени. Если в течении 2 часов средства не поступили на сайт — обратитесь в поддержку на нашем сайте",
          },
        ],
      },
    ],
  },
  {
    type: "cases",
    data: [
      {
        title: "Как открыть кейс?",
        description: [
          {
            text_1:
              "На главной странице сайта выберите интересующий вас кейс и нажмите на него. Далее пополните баланс на необходимую сумму для открытия кейса, нажав на кнопку «Пополнить баланс».",
            text_3:
              "После того, как баланс будет достаточный для открытия кейса - нажмите на кнопку «Открыть». При разовом открытии вы получите 1 предмет из содержимого кейса, которое показано внизу страницы. Отменить открытие кейса невозможно",
          },
        ],
      },
    ],
  },
  {
    type: "profile",
    data: [
      {
        title: "Как выйти из аккаунта?",
        description: [
          {
            text_1:
              "Для этого войдите в свой аккаунт нажатием на иконку профиля в правой верхней части экране а затем нажмите на кнопку “Выйти из аккаунта” ниже.",
          },
        ],
      },
      {
        title: "Как отключить звук на сайте или поменять язык?",
        description: [
          {
            text_1: "Воспользуйтесь значками рядом с иконкой профиля.",
          },
        ],
      },
    ],
  },
  {
    type: "items",
    data: [
      {
        title: "Как вставить трейд ссылку?",
        description: [
          {
            text_1:
              "Трейд-ссылку вы можете найти перейдя по ссылке - https://steamcommunity.com/my/tradeoffers/privacy Скопируйте трейд-ссылку, перейдите в ваш профиль на сайте, далее найдите кнопку «Показать ссылку на трейд», вставьте ее в появившемся окне и нажмите кнопку «Сохранить»",
          },
        ],
      },
      {
        title: "Как вывести предмет?",
        description: [
          {
            text_1:
              "Для запроса предмета перейдите в свой профиль на сайте и на нужном предмете нажмите оранжевую кнопку со стрелкой. После чего запустится таймер и в течение нескольких минут в ваш Steam профиль придет предложение обмена с вашим предметом",
          },
        ],
      },
      {
        title: "Как продать предмет и как вернуть проданный предмет?",
        description: [
          {
            text_1:
              "Перейдите в ваш профиль на сайте и на предмете, который хотите продать нажмите зеленую кнопку «Продать». Средства автоматически зачислятся на ваш баланс сайта. Однако вернуть проданный предмет будет уже нельзя.",
          },
        ],
      },
      {
        title: "Как отменить запрос предмета (трейд)?",
        description: [
          {
            text_1:
              "Не принимайте трейд в Steam. После истечения таймера, предмет вернется на сайт автоматически и будет снова доступен для использования",
          },
        ],
      },
    ],
  },
  {
    type: "cooperation",
    data: [
      {
        title: "Есть предложение о сотрудничестве, как с вами связаться?",
        description: [
          {
            text_1:
              "Все вопросы по сотрудничеству отправляйте на почту — info@take-drop.net",
            text_2: "В своем письме предоставьте:",
            list_1: "— Охват вашей аудитории",
            list_2: "— Статистику просмотров",
            list_3: "— Рекламную площадку",
            list_4: "— Также опишите свое предложение, как можно подробнее",
            text_4:
              "Мы свяжемся с вами, если ваше предложение заинтересует нас",
          },
        ],
      },
    ],
  },
];

export const FAQ = () => {
  const initialValue = localStorage.getItem("select_faq") || "payments";
  const [select, setSelect] = useState(initialValue);
  const [isopenPopup, setIsOpenPopup] = useState({});

  useEffect(() => {
    localStorage.setItem("select_faq", select);
  }, [select]);

  const selectedCategory = (type) => {
    setSelect(type);
  };

  const openPopup = (props) => {
    setIsOpenPopup({
      ...isopenPopup,
      [props]: !isopenPopup[props],
    });
  };

  console.log(isopenPopup);

  return (
    <div className="faq">
      <div className="faq__wrapper">
        <div className="faq__head mainWidht">
          <div className="faq__head-inner">
            <ButtonHome />
             {/* this component only return to home page! */}
            <EscapeToHome />
            <h2 className="faq__head-title">ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</h2>
          </div>
        </div>
      </div>
      <div className="faq__categories">
        <div className="faq__categories-list">
          {faqCategory.map((item, index) => (
            <div
              className={`faq__categories-item ${
                select === item.type ? "selected" : ""
              }`}
              onClick={() => selectedCategory(item.type)}
              key={index}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
      <div className="faq__content mainWidht">
        <div className="faq__content-inner">
          {contentCategory.map(
            (category) =>
              select === category.type && (
                <div className="faq__content-accordion" key={category.type}>
                  {category.data.map((item) => (
                    <div
                      className={`faq__content-accordion_inner ${
                        isopenPopup[item.title] ? "active" : ""
                      }`}
                      // style={{
                      //   height: isopenPopup[item.title] ? "auto" : "124px",
                      // }}
                      key={item.title}
                    >
                      <div className="faq__content-accordion_head">
                        <h3 className="faq__content-accordion_head-name">
                          {item.title}
                        </h3>
                        <button
                          className="faq__content-accordion_head-button"
                          onClick={() => openPopup(item.title)}
                        >
                          {/* <img src={link_img.plus} alt={item.title} /> */}
                          {isopenPopup[item.title] ? (
                            <img src={link_img.minus} alt={item.title} />
                          ) : (
                            <img src={link_img.plus} alt={item.title} />
                          )}
                        </button>
                      </div>
                      <ul
                        className={`faq__content-accordion_list ${
                          isopenPopup[item.title] ? "" : "invisible"
                        }`}
                      >
                        {item.description.map((desc) => (
                          <li
                            key={desc.text_1}
                            className="faq__content-accordion_list-item"
                          >
                            <p>{desc.text_1}</p>
                            {desc.text_2 && <p>{desc.text_2}</p>}
                            {desc.text_3 && (
                              <p className="faq__content-accordion_list-item_gap">
                                {desc.text_3}
                              </p>
                            )}
                            {desc.list_1 && <p>{desc.list_1}</p>}
                            {desc.list_2 && <p>{desc.list_2}</p>}
                            {desc.list_3 && <p>{desc.list_3}</p>}
                            {desc.list_4 && <p>{desc.list_4}</p>}
                            {desc.text_4 && <p>{desc.text_4}</p>}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";

import Echo from "laravel-echo";
import Pusher from "pusher-js";

import { Item } from "./Winnings/Item";
import { useGetInfoQuery } from "../../redux/cases/cases";
import { WinInfoLoading } from "../Loading/WinInfoLoading/WinInfoLoading";

export const HeaderWinnings = () => {
  const [online, setOnline] = useState(0);
  const [selectedDrops, setSelectedDrops] = useState("last_drop");
  const [isFixed, setIsFixed] = useState(false);

  const [liveDrop, setLiveDrop] = useState([]);
  const [topDrop, setTopDrop] = useState([]);

  const {
    data: InfoData,
    isSuccess: isInfoDataSuccess,
    isLoading,
  } = useGetInfoQuery();

  useEffect(() => {
    setLiveDrop(InfoData?.data?.last_drop);
    setTopDrop(InfoData?.data?.top_drop);
  }, [isInfoDataSuccess]);

  function langSwap(drops) {
    if (localStorage.getItem("i18nextLng") == "en") {
      drops.case_name = drops.case_name_en;
      drops.item_name = drops.item_name_en;
      drops.item_rarity = drops.item_rarity_en;
      drops.item_wear = drops.item_wear_en;
    }

    return drops;
  }

  function websocket() {
    if (window.Echo !== undefined) {
      return;
    }
    window.Pusher = Pusher;

    window.Echo = new Echo({
      broadcaster: "pusher",
      key: "18xwd7j98lwfnoenqho973ee9k81wkbv",
      wsHost: "ws.take-drop.net",
      wsPort: 80,
      cluster: "mt1",
      wssPort: 443,
      forceTLS: false,
      enabledTransports: ["ws", "wss"],
    });

    window.Echo.channel("main")
      .listen(".live.drop", (drops) => {
        setTimeout(() => {
          if (localStorage.getItem("i18nextLng") == "en") {
            drops.case_name = drops.case_name_en;
            drops.item_name = drops.item_name_en;
            drops.item_rarity = drops.item_rarity_en;
            drops.item_wear = drops.item_wear_en;
          }

          drops = langSwap(drops);
          setLiveDrop((prev) => {
            console.log(drops);
            if (prev) {
              return [drops, ...prev.slice(0, 29)];
            } else {
              return [drops];
            }
          });
        }, 10150);
      })
      .listen(".live.top", (drops) => {
        setTimeout(() => {
          drops = langSwap(drops);
          setTopDrop((prev) => {
            if (prev) {
              return [drops, ...prev.slice(0, 29)];
            } else {
              return [drops];
            }
          });
        }, 10150);
      })
      .listen(".live.online", (e) => {
        setOnline(e.online);
      });
  }

  useEffect(() => {
    // if (isInfoDataSuccess) {
    websocket();
    // }
  }, []);

  const handleCategoryInfo = (id) => {
    setSelectedDrops(id);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={isFixed ? "HeaderWinnings-active" : ""}>
      <div className={`HeaderWinnings ${isFixed ? "fixed" : ""}`}>
        <div className="HeaderWinningsWrapper">
          <div className="HeaderWinningsOnline">
            <div className="onlineBlock">
              <svg
                className="onlineSvg"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
              >
                <path
                  d="M10.1185 7.81074C10.4822 7.92256 10.8575 8.00009 11.2501 8.00009C13.421 8.00009 15.1876 6.20535 15.1876 4.00009C15.1876 1.79465 13.421 0 11.2501 0C10.8575 0 10.4822 0.0775314 10.1185 0.189257C11.1504 1.13143 11.8126 2.48283 11.8126 4C11.8126 5.51717 11.1504 6.86857 10.1185 7.81074ZM1.02393 10.8181C4.44285 8.6261 9.05661 8.6261 12.4766 10.8181C13.1078 11.2221 13.5 11.9581 13.5 12.7388V16H0V12.7388C0 11.9581 0.39222 11.2221 1.02393 10.8181ZM11.8408 9.18482C13.6667 9.2864 15.4335 9.82903 16.9766 10.8181C17.6078 11.2227 18 11.9588 18 12.7388V16H14.625V12.7388C14.625 11.5692 14.0317 10.4626 13.077 9.85161C12.6808 9.59762 12.2629 9.3856 11.8408 9.18482ZM9.53433 6.82843C11.072 5.26629 11.072 2.73371 9.53433 1.17157C7.99659 -0.3904 5.5035 -0.390583 3.96585 1.17157C2.42811 2.73371 2.42811 5.26629 3.96585 6.82843C5.5035 8.39058 7.99659 8.39058 9.53433 6.82843Z"
                  fill="white"
                />
              </svg>
              <div className="name">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="4"
                  height="4"
                  viewBox="0 0 4 4"
                  fill="none"
                >
                  <path
                    d="M2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.89543 0 0 0.89543 0 2C0 3.10457 0.89543 4 2 4Z"
                    fill="#5DEC7D"
                  />
                </svg>
                Online
              </div>
              <p className="onlineNumber">{online}</p>
            </div>

            <div className="rouletteSelection">
              <div
                className={`item last_drop ${
                  selectedDrops === "last_drop" ? "active" : ""
                }`}
                onClick={() => handleCategoryInfo("last_drop")}
              >
                <span>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="10"
                      height="10"
                      rx="5"
                      fill="url(#paint0_radial_51_11)"
                    />
                    <defs>
                      <radialGradient
                        id="paint0_radial_51_11"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(5 5) rotate(45) scale(7.07107)"
                      >
                        <stop
                          stopColor={`${
                            selectedDrops === "last_drop"
                              ? "#EFBB0A"
                              : "#606062"
                          }`}
                        />
                        <stop
                          offset="0.3"
                          stopColor={`${
                            selectedDrops === "last_drop"
                              ? "#EFBB0A"
                              : "#606062"
                          }`}
                        />
                        <stop
                          offset="0.3"
                          stopColor={`${
                            selectedDrops === "last_drop"
                              ? "#EFBB0A"
                              : "#606062"
                          }`}
                          stopOpacity="0.2"
                        />
                        <stop
                          offset="1"
                          stopColor={`${
                            selectedDrops === "last_drop"
                              ? "#EFBB0A"
                              : "#606062"
                          }`}
                          stopOpacity="0.2"
                        />
                      </radialGradient>
                    </defs>
                  </svg>
                </span>
                live
              </div>
              <div
                className={`item top_drop ${
                  selectedDrops === "top_drop" ? "active" : ""
                }`}
                onClick={() => handleCategoryInfo("top_drop")}
              >
                <span>
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVG" clipPath="url(#clip0_51_3)">
                      <g id="Clip path group">
                        <mask
                          id="mask0_51_3"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="12"
                          height="12"
                        >
                          <g id="shared-star-id-a">
                            <path
                              id="Vector"
                              d="M0 0H12V12H0V0Z"
                              fill="white"
                            />
                          </g>
                        </mask>
                        <g mask="url(#mask0_51_3)">
                          <g id="Group">
                            <path
                              id="Vector_2"
                              d="M11.9699 4.59982C11.9313 4.48072 11.8586 4.37556 11.7609 4.29735C11.6631 4.21915 11.5446 4.17133 11.4199 4.15982L7.95991 3.84982L6.58991 0.649821C6.54108 0.533653 6.45904 0.434484 6.35409 0.364748C6.24913 0.295012 6.12592 0.257812 5.99991 0.257812C5.87389 0.257812 5.75069 0.295012 5.64573 0.364748C5.54077 0.434484 5.45874 0.533653 5.40991 0.649821L4.03991 3.84982L0.579908 4.15982C0.455225 4.17158 0.336732 4.21966 0.239109 4.29811C0.141487 4.37655 0.0690251 4.48192 0.0307017 4.60115C-0.00762162 4.72038 -0.0101223 4.84823 0.0235094 4.96886C0.0571411 5.0895 0.125427 5.19762 0.219908 5.27982L2.83991 7.57982L2.05991 10.9798C2.03285 11.1016 2.04198 11.2286 2.08616 11.3453C2.13033 11.4619 2.20764 11.5631 2.30857 11.6364C2.4095 11.7098 2.52965 11.752 2.65425 11.7579C2.77885 11.7638 2.90247 11.7332 3.00991 11.6698L5.99991 9.87982L8.98991 11.6698C9.09813 11.7344 9.22301 11.7656 9.34888 11.7595C9.47475 11.7533 9.59602 11.7102 9.69748 11.6354C9.79893 11.5607 9.87606 11.4576 9.91918 11.3392C9.96231 11.2208 9.96952 11.0923 9.93991 10.9698L9.15991 7.56982L11.7799 5.27982C11.9799 5.10982 12.0499 4.84982 11.9699 4.59982Z"
                              fill={`${
                                selectedDrops === "top_drop"
                                  ? "#EFBB0A"
                                  : "#606062"
                              }`}
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_51_3">
                        <rect width="12" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                top
              </div>
            </div>
          </div>
          <div className="HeaderDropsItems">
  {isLoading
    ? Array.from({ length: 30 }, (_, index) => (
        <div
          key={index}
          style={{ animationDelay: `${index * 0.1}s` }}
          className="item-animation"
        >
          <WinInfoLoading />
        </div>
      ))
    : selectedDrops === "last_drop"
    ? liveDrop?.map((item, index) => (
        <div
          key={index}
          style={{ animationDelay: `${index * 0.1}s` }}
          className="item-animation"
        >
          <Item
            rarity={item.item_rarity}
            image={item.item_image}
            caseImg={item.case_image}
            itemName={item.item_name}
            userImg={item.user_image}
            userName={item.user_name}
            user_id={item.user_id}
            case_url={item.case_url}
          />
        </div>
      ))
    : topDrop?.map((item, index) => (
        <div
          key={index}
          style={{ animationDelay: `${index * 0.1}s` }}
          className="item-animation"
        >
          <Item
            rarity={item.item_rarity}
            image={item.item_image}
            caseImg={item.case_image}
            itemName={item.item_name}
            userImg={item.user_image}
            userName={item.user_name}
            user_id={item.user_id}
            case_url={item.case_url}
          />
        </div>
      ))}
</div>

        </div>
      </div>
    </div>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const ButtonReplenish = ({ handleClick }) => {
  const { t } = useTranslation();

  const isAuth = document.cookie
    ?.split("; ")
    .find((row) => row?.startsWith("access_token="));

  const payment = () => {
    if (isAuth) {
      handleClick();
    }
  };

  return (
    <>
      <button className="button-replenish" onClick={payment}>
        {t("replenish")}
      </button>
      {!isAuth && <span className="unAuth">{t("notAuthorized")}</span>}
    </>
  );
};

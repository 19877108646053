import React from "react";

import "./Notification.scss";
import { useTranslation } from "react-i18next";

export const NotificationCase = ({
  price,
  showNotification,
  selectCountCase,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`notification ${showNotification ? "open" : ""}`}>
      <div className="notificationInner">
        <div className="notificationHead">{t("ready")}</div>
        <p className="notificationText">{price}</p>
        <div className="notificationLine"></div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";

import "./CaseOpen.scss";

import { useTranslation } from "react-i18next";
import { CaseOpens } from "./CaseOpens";
import { useSelector } from "react-redux";
export const CaseOpen = ({
  name,
  item,
  color,
  dataWin,
  winner,
  translateX,
  isSpinning,
  setSold,
  sold,
  isWin,
  handleOpenMore,
  multipliedItems,
  setShowNotification,
  playAudioOpen,
  isMuted,
  selectCountCase,
  setSalePriceResponse,
  caseOpening,
  handleSaleItem,
  soldItems,
  dropItems,
  setDropItems,
  setSoldAll,
  soldAll,
  handleSaleAllItem,
}) => {
  const { t } = useTranslation();

  const soldsItems = useSelector((state) => state.soldItemsSlice);
  const [formattedSum, setFormattedSum] = useState(0);
  useEffect(() => {
    if (dataWin) {
      setDropItems(dataWin);
    }
  }, [dataWin]);

  useEffect(() => {
    const price = soldsItems.map((item) => item.price);
    const sum = price.reduce((acc, curr) => acc + curr, 0);
    setFormattedSum(sum.toFixed(2));
  }, [soldsItems]);

  const handleSaleAllItems = () => {
    const validIds = soldsItems
      .filter((item) => !item.deleted)
      .map((item) => item.id);
    handleSaleAllItem(validIds);
  };

  return (
    <>
      <h2 className="CaseName">{name}</h2>
      {/* ОТКРЫТИЕ ОДНОГО КЕЙСА */}
      {selectCountCase === 1 && (
        <div className="caseOpenOne">
          <CaseOpens
            selectCountCase={selectCountCase}
            playAudioOpen={playAudioOpen}
            drop={dataWin}
            item={item}
            multipliedItems={multipliedItems[0]}
            translateX={translateX}
            winner={winner}
            color={color}
            isWin={isWin}
            isMuted={isMuted}
            caseOpening={caseOpening}
          />
        </div>
      )}
      <div className="case_wrapper">
        {/* ОТРЫТИЕ ДВУХ КЕЙСОВ */}
        {!winner && selectCountCase === 2 && (
          <div className="CaseOpenTwo">
            <CaseOpens
              selectCountCase={selectCountCase}
              playAudioOpen={playAudioOpen}
              drop={dataWin}
              item={item}
              multipliedItems={multipliedItems[0]}
              translateX={translateX}
              winner={winner}
              color={color}
              isWin={isWin}
              isMuted={isMuted}
              caseOpening={caseOpening}
            />
            <CaseOpens
              selectCountCase={selectCountCase}
              playAudioOpen={playAudioOpen}
              drop={dataWin}
              item={item}
              multipliedItems={multipliedItems[1]}
              translateX={translateX}
              winner={winner}
              color={color}
              isWin={isWin}
              isMuted={isMuted}
              caseOpening={caseOpening}
            />
          </div>
        )}

        {/* ОТКРЫТИЕ ТРЕХ КЕЙСОВ */}
        {!winner && selectCountCase === 3 && (
          <div className="CaseOpenThree">
            <div className="CaseOpenTwo">
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[0]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[1]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
            </div>
            <div className="caseOpenOne">
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[2]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
            </div>
          </div>
        )}

        {/* ОТКРЫТИЕ ЧЕТЫРЕХ КЕЙСОВ */}
        {!winner && selectCountCase === 4 && (
          <>
            <div className="CaseOpenTwo">
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[0]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[1]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
            </div>
            <div className="CaseOpenTwo">
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[2]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[3]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
            </div>
          </>
        )}

        {/* ОТКРЫТИЕ ПЯТИ КЕЙСОВ */}
        {!winner && selectCountCase === 5 && (
          <div className="CaseOpenFive">
            <div className="CaseOpenTwo">
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[0]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[1]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
            </div>
            <div className="CaseOpenTwo">
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[2]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[3]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
            </div>
            <div className="caseOpenOne">
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[4]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
            </div>
          </div>
        )}

        {/* ОТКРЫТИЕ ДЕСЯТИ КЕЙСОВ */}
        {!winner && selectCountCase === 10 && (
          <>
            <div className="CaseOpenTwo">
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[0]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[1]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
            </div>
            <div className="CaseOpenTwo">
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[2]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[3]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
            </div>
            <div className="CaseOpenTwo">
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[4]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[5]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
            </div>
            <div className="CaseOpenTwo">
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[6]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[7]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
            </div>
            <div className="CaseOpenTwo">
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[8]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
              <CaseOpens
                selectCountCase={selectCountCase}
                playAudioOpen={playAudioOpen}
                drop={dataWin}
                item={item}
                multipliedItems={multipliedItems[9]}
                translateX={translateX}
                winner={winner}
                color={color}
                isWin={isWin}
                isMuted={isMuted}
                caseOpening={caseOpening}
                sold={sold}
              />
            </div>
          </>
        )}
        {winner && selectCountCase > 1 && (
          <div className="winning">
            <div
              className={`winning_item ${
                selectCountCase === 2
                  ? "two"
                  : selectCountCase === 3
                  ? "three"
                  : selectCountCase === 4
                  ? "four"
                  : selectCountCase === 5
                  ? "five"
                  : selectCountCase === 10
                  ? "ten"
                  : ""
              }`}
            >
              {dropItems.map((item, index) => (
                <div
                  className={`winning_item-lot ${
                    item.rarity === "Исключительный"
                      ? "blue"
                      : item.rarity === "Мастерский"
                      ? " red"
                      : item.rarity === "Заслуженный"
                      ? "blue-army"
                      : item.rarity === "Стандартное"
                      ? "blue-army"
                      : item.rarity === "Высшего класса"
                      ? "blue"
                      : item.rarity === "Примечательного типа"
                      ? "blue-army"
                      : item.rarity === "Экзотичного вида"
                      ? " pink"
                      : item.rarity === "Превосходный"
                      ? "pink"
                      : item.rarity === "Запрещённое"
                      ? "purple"
                      : item.rarity === "Промышленное качество"
                      ? "blue"
                      : item.rarity === "Тайное"
                      ? "red"
                      : item.rarity === "Ширпотреб"
                      ? "green"
                      : item.rarity === "Армейское качество"
                      ? "blue-army"
                      : item.rarity === "экстраординарного типа"
                      ? "yellow"
                      : item.rarity === "Контрабанда"
                      ? "yellow"
                      : item.rarity === "Засекреченное"
                      ? "pink"
                      : item.rarity === "Restricted"
                      ? "purple"
                      : item.rarity === "Industrial Grade"
                      ? "blue"
                      : item.rarity === "Covert"
                      ? "red"
                      : item.rarity === "Consumer Grade"
                      ? "green"
                      : item.rarity === "Mil-Spec"
                      ? "blue-army"
                      : item.rarity === "Extraordinary"
                      ? "yellow"
                      : item.rarity === "Contraband"
                      ? "yellow"
                      : item.rarity === "Classified"
                      ? "pink"
                      : item.rarity === "Remarkable"
                      ? "blue"
                      : item.rarity === "Masterpiece"
                      ? " red"
                      : item.rarity === "Distinguished"
                      ? "blue-army"
                      : item.rarity === "Standard"
                      ? "blue-army"
                      : item.rarity === "High Class"
                      ? "blue"
                      : item.rarity === "Exceptional"
                      ? "blue-army"
                      : item.rarity === "Exotic"
                      ? " pink"
                      : ""
                  }`}
                  key={index}
                >
                  <div
                    className={`winning_item-lot_shadow ${
                      item.rarity === "Исключительный"
                        ? "blue"
                        : item.rarity === "Мастерский"
                        ? " red"
                        : item.rarity === "Заслуженный"
                        ? "blue-army"
                        : item.rarity === "Стандартное"
                        ? "blue-army"
                        : item.rarity === "Высшего класса"
                        ? "blue"
                        : item.rarity === "Примечательного типа"
                        ? "blue-army"
                        : item.rarity === "Экзотичного вида"
                        ? " pink"
                        : item.rarity === "Превосходный"
                        ? "pink"
                        : item.rarity === "Запрещённое"
                        ? "purple"
                        : item.rarity === "Промышленное качество"
                        ? "blue"
                        : item.rarity === "Тайное"
                        ? "red"
                        : item.rarity === "Ширпотреб"
                        ? "green"
                        : item.rarity === "Армейское качество"
                        ? "blue-army"
                        : item.rarity === "экстраординарного типа"
                        ? "yellow"
                        : item.rarity === "Контрабанда"
                        ? "yellow"
                        : item.rarity === "Засекреченное"
                        ? "pink"
                        : item.rarity === "Restricted"
                        ? "purple"
                        : item.rarity === "Industrial Grade"
                        ? "blue"
                        : item.rarity === "Covert"
                        ? "red"
                        : item.rarity === "Consumer Grade"
                        ? "green"
                        : item.rarity === "Mil-Spec"
                        ? "blue-army"
                        : item.rarity === "Extraordinary"
                        ? "yellow"
                        : item.rarity === "Contraband"
                        ? "yellow"
                        : item.rarity === "Classified"
                        ? "pink"
                        : item.rarity === "Remarkable"
                        ? "blue"
                        : item.rarity === "Masterpiece"
                        ? " red"
                        : item.rarity === "Distinguished"
                        ? "blue-army"
                        : item.rarity === "Standard"
                        ? "blue-army"
                        : item.rarity === "High Class"
                        ? "blue"
                        : item.rarity === "Exceptional"
                        ? "blue-army"
                        : item.rarity === "Exotic"
                        ? " pink"
                        : ""
                    }`}
                  ></div>
                  <div
                    className={`winning_item-lot_border ${
                      item.rarity === "Исключительный"
                        ? "blue"
                        : item.rarity === "Мастерский"
                        ? " red"
                        : item.rarity === "Заслуженный"
                        ? "blue-army"
                        : item.rarity === "Стандартное"
                        ? "blue-army"
                        : item.rarity === "Высшего класса"
                        ? "blue"
                        : item.rarity === "Примечательного типа"
                        ? "blue-army"
                        : item.rarity === "Экзотичного вида"
                        ? " pink"
                        : item.rarity === "Превосходный"
                        ? "pink"
                        : item.rarity === "Запрещённое"
                        ? "purple"
                        : item.rarity === "Промышленное качество"
                        ? "blue"
                        : item.rarity === "Тайное"
                        ? "red"
                        : item.rarity === "Ширпотреб"
                        ? "green"
                        : item.rarity === "Армейское качество"
                        ? "blue-army"
                        : item.rarity === "экстраординарного типа"
                        ? "yellow"
                        : item.rarity === "Контрабанда"
                        ? "yellow"
                        : item.rarity === "Засекреченное"
                        ? "pink"
                        : item.rarity === "Restricted"
                        ? "purple"
                        : item.rarity === "Industrial Grade"
                        ? "blue"
                        : item.rarity === "Covert"
                        ? "red"
                        : item.rarity === "Consumer Grade"
                        ? "green"
                        : item.rarity === "Mil-Spec"
                        ? "blue-army"
                        : item.rarity === "Extraordinary"
                        ? "yellow"
                        : item.rarity === "Contraband"
                        ? "yellow"
                        : item.rarity === "Classified"
                        ? "pink"
                        : item.rarity === "Remarkable"
                        ? "blue"
                        : item.rarity === "Masterpiece"
                        ? " red"
                        : item.rarity === "Distinguished"
                        ? "blue-army"
                        : item.rarity === "Standard"
                        ? "blue-army"
                        : item.rarity === "High Class"
                        ? "blue"
                        : item.rarity === "Exceptional"
                        ? "blue-army"
                        : item.rarity === "Exotic"
                        ? " pink"
                        : ""
                    }`}
                  >
                    <img src={item.image} alt={item.name} />
                  </div>
                  <div className="winning_item-lot_description">
                    <h3>{item.name}</h3>
                    <button
                      className="winning_item-lot_sale"
                      onClick={() => handleSaleItem(item.id)}
                      disabled={soldItems.includes(item.id) || sold}
                    >
                      <span>{sold ? t("soldFor") : t("sellFor")}</span>{" "}
                      {item.price} ₽
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* КНОПКИ ПОД РУЛЕТКОЙ */}
      <div className={`CaseOpenBtn ${selectCountCase > 1 ? "sm" : ""}`}>
        {winner &&
          selectCountCase === 1 &&
          dataWin &&
          dataWin.map((item, index) => (
            <div
              className="button"
              style={{ position: "relative" }}
              key={index}
            >
              <button
                className={`tryAgain btn ${color}`}
                onClick={handleOpenMore}
                disabled={isSpinning || sold}
              >
                {t("tryAgain")}
              </button>
              <button
                className="sell btn"
                onClick={() => handleSaleItem(item.id)}
                disabled={soldItems.includes(item.id)}
              >
                {!sold
                  ? `${t("sellFor")} ${formattedSum}₽`
                  : `${t("soldFor")} ${formattedSum} ₽`}
              </button>
            </div>
          ))}
        {winner && selectCountCase > 1 && dataWin && (
          <div className="button" style={{ position: "relative" }}>
            <button
              className={`sell btn ${color}`}
              onClick={handleSaleAllItems}
              disabled={isSpinning || sold}
            >
              {!sold
                ? `${t("sellFor")} ${formattedSum}₽`
                : `${t("soldFor")} ${formattedSum} ₽`}
            </button>
            <button
              className={`tryAgain btn ${color}`}
              onClick={handleOpenMore}
              disabled={isSpinning}
            >
              {t("tryAgain")}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

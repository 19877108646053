import React from "react";

import "./Loading.scss";
import link_img from "../../img/link_img";

export const Loading = () => {
  return (
    <div className="Loading">
      <div className="LoadingContent">
        <img src={link_img.logo} alt="" />
      </div>
    </div>
  );
};

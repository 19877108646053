import React, { useEffect, useState } from "react";

import "../components/OpenCase/SelectedCase.scss";

import useSound from "use-sound";

import { ReturnHomeButton } from "../components/ReturnHomeButton/ReturnHomeButton";
import { EscapeToHome } from "../components/EscapeToHome/EscapeToHome";
import { Case } from "../components/OpenCase/CaseBlock/Case";
import { ContentsCase } from "../components/OpenCase/ContentsCase/ContentsCase";

import { useParams } from "react-router-dom";
import {
  useGetCaseByUrlQuery,
  useLazyGetOpenCaseQuery,
  useGetUserItemsQuery,
  useLazyGetItemSaleQuery,
  useGetUserQuery,
  useGetSaleItemsMutation,
} from "../redux/cases/cases.js";
import { useSelector, useDispatch } from "react-redux";
import { CaseOpen } from "../components/OpenCase/CaseOpen/CaseOpen";
import { NotificationCase } from "../components/Notification/NotificationCase";
import { Loading } from "../components/Loading/Loading";

import case_sound_sharpness from "../sounds/sounds";
import { WinningItem } from "../components/WinningItem/WinningItem.jsx";

import {
  setSoldsItems,
  removeFromSoldItems,
} from "../redux/slices/soldItemsSlice.js";

export const SelectedCase = ({ setLogin, login, isMuted, setPayments }) => {
  const { url } = useParams();

  const { data: caseInfo, isLoading } = useGetCaseByUrlQuery(url);

  const [showNotification, setShowNotification] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const [open, { data: dataWin }] = useLazyGetOpenCaseQuery();

  const [playAudioOpens, { stop }] = useSound(
    case_sound_sharpness.case_sound_sharpness
    // { volume: 0.5 }
  );

  useEffect(() => {
    if (url) {
      window.scrollTo(0, 0);
    }
  }, [url]);

  const [selectCountCase, setSelectCountCase] = useState(1);
  const [multipliedItems, setMultipliedItems] = useState(
    Array(selectCountCase).fill([])
  );

  const [translateX, setTranslateX] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [caseOpen, setCaseOpen] = useState(false);
  const [winner, setWinner] = useState(false);
  const [sold, setSold] = useState(false);
  const [soldAll, setSoldAll] = useState(false);
  const [isWin, setWin] = useState(false);
  const [salePriceResponse, setSalePriceResponse] = useState();
  const [caseOpening, setCaseOpening] = useState();
  const [fastWin, setFastWin] = useState(false);

  const shuffleItems = (itemsToShuffle) => {
    let shuffledItems = itemsToShuffle.slice();
    for (let i = shuffledItems.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledItems[i], shuffledItems[j]] = [
        shuffledItems[j],
        shuffledItems[i],
      ];
    }
    return shuffledItems;
  };

  const initializeAndShuffleItems = () => {
    if (caseInfo?.data && dataWin?.data && dataWin?.data.drops.length > 0) {
      const items = caseInfo.data.items.slice();

      while (items.length < 31) {
        items.push(...caseInfo.data.items);
      }

      dataWin.data.drops.forEach((drop, index) => {
        const additionalItem = drop;
        items.push(additionalItem);

        let shuffledItems = shuffleItems(items);

        const additionalItemIndex = shuffledItems.indexOf(additionalItem);

        if (additionalItemIndex !== 31) {
          [shuffledItems[additionalItemIndex], shuffledItems[28]] = [
            shuffledItems[28],
            shuffledItems[additionalItemIndex],
          ];
        }

        shuffledItems = shuffledItems.slice(0, 32);
        setMultipliedItems((prevItems) => {
          const newItems = [...prevItems];
          newItems[index] = shuffledItems;
          return newItems;
        });
      });
    }
  };

  useEffect(() => {
    if (!fastWin) {
      initializeAndShuffleItems();
    }
  }, [caseInfo?.data, dataWin, fastWin]);

  useEffect(() => {
    if (!fastWin && caseOpen) {
      const delayTime =
        caseOpening === "open" ? 5900 : caseOpening === "fast" ? 1820 : 0;
      const delayTimeWin =
        caseOpening === "open" ? 6550 : caseOpening === "fast" ? 2650 : 0;

      const timer = setTimeout(() => {
        setIsSpinning(false);
        setWin(true);
        console.clear();
      }, delayTime);

      const timerWinner = setTimeout(() => {
        setWinner(true);
      }, delayTimeWin);
      return () => clearTimeout(timer, timerWinner);
    }
  }, [caseOpen, fastWin]);

  const findLastIndexWithName = (arr, name) => {
    for (let i = arr.length - 4; i >= 0; i--) {
      if (arr[i] && arr[i].name === name) {
        console.log("i", i);
        return i;
      }
    }
    return -1;
  };

  const itemWidth = 190;

  const handleOpenMore = () => {
    initializeAndShuffleItems();

    setTranslateX(0);

    setCaseOpen(false);
    setFastWin(false);
    setWinner(false);
    setWin(false);
    setSold(false);
    setOpen(false);

    stop();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!fastWin && dataWin && dataWin?.data) {
        const openedItemName = dataWin.data.drops.map((item) => item.name)[0];
        const lastItemIndex = findLastIndexWithName(
          multipliedItems[0],
          openedItemName
        );

        console.log(lastItemIndex);

        const getRandomNumber = (min, max) => Math.random() * (max - min) + min;
        const random = getRandomNumber(0.66, 1.47);

        const screenCenterOffset = (5 * itemWidth) / 2;
        const cardCenterOffset = itemWidth / random;

        console.log(screenCenterOffset);
        console.log(cardCenterOffset);
        console.log(
          lastItemIndex * itemWidth - (screenCenterOffset - cardCenterOffset)
        );

        if (lastItemIndex !== -1) {
          const leftPosition =
            lastItemIndex * itemWidth - (screenCenterOffset - cardCenterOffset);
          const maxTranslate = multipliedItems[0].length * itemWidth;
          console.log(leftPosition);
          console.log(maxTranslate);
          setTranslateX(-Math.min(leftPosition, maxTranslate));
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [dataWin, multipliedItems, itemWidth, fastWin]);

  useEffect(() => {
    if (!fastWin && dataWin?.data) {
      const openedItemName = dataWin.data.drops.map((item) => item.name)[0];
      const lastItemIndex = findLastIndexWithName(
        multipliedItems[0],
        openedItemName
      );

      const screenCenterOffset = (5 * itemWidth) / 2;
      const cardCenterOffsetWin = itemWidth / 0.95;

      const leftPosition =
        lastItemIndex * itemWidth - (screenCenterOffset - cardCenterOffsetWin);
      const maxTranslate = multipliedItems[0].length * itemWidth;
      setTranslateX(-Math.min(leftPosition, maxTranslate));
    }
  }, [isWin, fastWin]);

  useEffect(() => {
    setTimeout(() => {
      if (!fastWin && dataWin && dataWin?.data && caseOpen) {
        if (!isMuted && caseOpening !== "fast") {
          playAudioOpens();
        } else {
          stop();
        }
      }
    }, 0);
  }, [dataWin, isMuted, caseOpening, fastWin, caseOpen]);

  // SALE ITEM
  const { start_price, end_price, page } = useSelector(
    (state) => state.filterCase
  );
  const { refetch: refetchUserItems } = useGetUserItemsQuery({
    start_price,
    end_price,
    page,
  });
  const { refetch: refetchUserData } = useGetUserQuery(null);
  const [sale] = useLazyGetItemSaleQuery();
  const [soldItems, setSoldItems] = useState([]);
  const [dropItems, setDropItems] = useState();
  const dispatch = useDispatch();
  const soldsItems = useSelector((state) => state.soldItemsSlice);

  const handleSaleItem = async (id) => {
    const { data: saleResponse } = await sale(id);

    setSalePriceResponse(saleResponse?.message);

    refetchUserItems({ start_price, end_price, page });

    // setSold(true);
    dispatch(removeFromSoldItems(id));

    setShowNotification(true);

    setSoldItems([...soldItems, id]);
    setTimeout(() => {
      refetchUserData();
    }, 100);

    setTimeout(() => {
      setShowNotification(false);
    }, 3350);
  };

  const [allSale] = useGetSaleItemsMutation();
  //
  const handleSaleAllItem = async (ids) => {
    try {
      const response = await allSale(ids);
      if (response.data) {
        setSalePriceResponse(response.data.message);
        setSold(true);
        setShowNotification(true);
        setTimeout(() => {
          refetchUserData();
          refetchUserItems({ start_price, end_price, page });
        }, 100);
        setTimeout(() => {
          setShowNotification(false);
        }, 3350);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //
  useEffect(() => {
    if (dataWin) {
      setDropItems(dataWin?.data.drops);
      dispatch(setSoldsItems(dataWin?.data.drops));
    }
  }, [dataWin]);

  useEffect(() => {
    console.log(soldsItems);
  }, [soldsItems]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="SelectedCase">
      <div className="main mainWidht">
        <ReturnHomeButton name={caseInfo?.data?.name} stop={stop} />
         {/* this component only return to home page! */}
         <EscapeToHome />
        {isOpen && (
          <div className="CaseOpen">
            <CaseOpen
              open={open}
              name={caseInfo?.data.name}
              item={caseInfo?.data.items}
              color={caseInfo?.data.color}
              dataWin={dataWin?.data.drops}
              winner={winner}
              translateX={translateX}
              isSpinning={isSpinning}
              setSold={setSold}
              sold={sold}
              setSoldAll={setSoldAll}
              soldAll={soldAll}
              isWin={isWin}
              setShowNotification={setShowNotification}
              multipliedItems={multipliedItems}
              handleOpenMore={handleOpenMore}
              initializeAndShuffleItems={initializeAndShuffleItems}
              isMuted={isMuted}
              selectCountCase={selectCountCase}
              setSalePriceResponse={setSalePriceResponse}
              caseOpening={caseOpening}
              handleSaleItem={handleSaleItem}
              soldItems={soldItems}
              setDropItems={setDropItems}
              dropItems={dropItems}
              handleSaleAllItem={handleSaleAllItem}
            />
          </div>
        )}
        {!isOpen && !fastWin && (
          <Case
            setIsSpinning={setIsSpinning}
            open={open}
            stop={stop}
            name={caseInfo?.data.name}
            price={caseInfo?.data?.price}
            image={caseInfo?.data?.image}
            url={caseInfo?.data?.url}
            color={caseInfo?.data?.color}
            setOpen={setOpen}
            setTranslateX={setTranslateX}
            setCaseOpen={setCaseOpen}
            dataWin={dataWin}
            findLastIndexWithName={findLastIndexWithName}
            multipliedItems={multipliedItems}
            itemWidth={itemWidth}
            setLogin={setLogin}
            isMuted={isMuted}
            playAudioOpens={playAudioOpens}
            selectCountCase={selectCountCase}
            setSelectCountCase={setSelectCountCase}
            setCaseOpening={setCaseOpening}
            setWin={setWin}
            setWinner={setWinner}
            setFastWin={setFastWin}
            setPayments={setPayments}
          />
        )}
        {fastWin && (
          <WinningItem
            winner={winner}
            selectCountCase={selectCountCase}
            dropItems={dropItems}
            soldItems={soldItems}
            color={caseInfo?.data?.color}
            handleSaleItem={handleSaleItem}
            fastWin={fastWin}
            isSpinning={isSpinning}
            handleOpenMore={handleOpenMore}
            sold={sold}
            handleSaleAllItem={handleSaleAllItem}
          />
        )}
        <ContentsCase items={caseInfo?.data?.items} />

        {/* КЕЙСЫ, КОНТРАКТЫ, АПРГРЕЙДЫ, ПОЛЬЗОВАТЕЛЕЙ, ОНЛАЙН */}
        {/* <About /> */}

        <NotificationCase
          price={salePriceResponse}
          showNotification={showNotification}
          selectCountCase={selectCountCase}
        />
      </div>
    </div>
  );
};

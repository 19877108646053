import React from "react";

import "./privacyPolicy.scss";

import { Link } from "react-router-dom";
import { ButtonHome } from "../../components/ButtonHome/ButtonHome";
import { EscapeToHome } from "../../components/EscapeToHome/EscapeToHome";

export const PrivacyPolicy = () => {
  return (
    <div className="policy">
      <div className="mainWidht">
        <ButtonHome />
        {/* this component only return to home page! */}
        <EscapeToHome />
        <div className="policy__content">
          <h2 className="policy__content-title">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h2>
          <div className="policy__text-content">
            <h3 className="policy__text-content-head">
              ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ
            </h3>
            <div className="policy__text-block">
              <h4 className="policy__text-block-subitem">1. введение</h4>
              <div className="policy__text-block-items">
                <p className="policy__text-block-item">
                  Используя и/или посещая любой раздел веб-сайта take-drop.net
                  ("Веб-сайт"); или открывая учетную запись на Веб-сайте, вы
                  соглашаетесь соблюдать Политику конфиденциальности. Эта
                  Политика конфиденциальности описывает, как мы собираем,
                  используем, обрабатываем и раскрываем вашу информацию, включая
                  личную информацию, в связи с вашим доступом к данному
                  Веб-сайту и его использованием.
                </p>
                <p className="policy__text-block-item">
                  "Контролер данных" - это компания, которая определяет цели и
                  средства обработки персональных данных в соответствии с
                  настоящей Политикой конфиденциальности. LTD Una Planeta
                  выступает в качестве контролера данных в соответствии с
                  Регламентом ЕС 2016/679, определяющим цели и средства
                  обработки ваших персональных данных. При использовании
                  платежных сервисов вы также будете предоставлять свою
                  информацию, включая персональные данные, компании LTD Una
                  Planeta, регистрационный номер 415100090, зарегистрированной
                  по адресу: Sakartvelo, K. Poti, Rustavelis Rkali 12, Bina 21,
                  Georgia. LTD Una Planeta будет обрабатывать вашу информацию,
                  необходимую для обработки платежей, и будет выступать в
                  качестве контролера данных ("Контролер платежных данных") в
                  отношении вашей информации, необходимой для анализа и
                  сообщения о действиях, которые вызывают подозрения в
                  мошенничестве, отмывании денег, финансировании терроризма или
                  любых других незаконных действиях. деятельность в соответствии
                  с применимым законодательством, как более подробно указано
                  ниже.
                </p>
                <p className="policy__text-block-item">
                  Для целей настоящей политики использование терминов "мы",
                  "нас" или "наш" относится к компании, которая несет
                  ответственность за вашу информацию в соответствии с настоящей
                  Политикой конфиденциальности. Пожалуйста, обратитесь к разделу
                  9 для получения контактных данных контролеров данных.
                </p>
              </div>
            </div>

            {/*  */}
            <div className="policy__text-block">
              <h4 className="policy__text-block-subitem">
                2. информация, которую мы собираем
              </h4>
              <div className="policy__text-block-items">
                <p className="policy__text-block-item">
                  Мы собираем вашу личную информацию, которая может быть
                  использована для идентификации наших клиентов и партнеров,
                  включая, но не ограничиваясь этим, имя и фамилию, дату
                  рождения, реквизиты банковского счета, домашний или иной
                  физический адрес, адрес электронной почты, номер телефона,
                  информацию о доставке, платежную информацию, историю
                  транзакций, предпочтения в использовании веб-сайта, а также
                  отзывы о Веб-сайте или другая контактная информация являются
                  личной информацией ("Личная информация").
                </p>
              </div>
            </div>

            {/*  */}
            <div className="policy__text-block">
              <h4 className="policy__text-block-subitem">
                2.1 личная информация, которую вы нам представляете
              </h4>
              <div className="policy__text-block-items">
                <p className="policy__text-block-item">
                  Мы могли бы попросить вас предоставить нам вашу личную
                  информацию. В некоторых случаях мы могли бы запросить у вас
                  информацию посредством опросов или конкурсов. Участие в этих
                  опросах или конкурсах полностью осуществляется на добровольной
                  основе, и, исходя из этого, вы вольны принимать решение
                  относительно раскрытия такой информации. Запрашиваемая нами
                  информация может быть связана с контактной информацией (такой
                  как имя, почтовый адрес и номер телефона) и демографической
                  информацией (такой как почтовый индекс или возраст).
                </p>
              </div>
            </div>

            {/*  */}
            <div className="policy__text-block">
              <h4 className="policy__text-block-subitem">
                2.2 личная информация, которую мы собираем о вас
              </h4>
              <div className="policy__text-block-items">
                <p className="policy__text-block-item">
                  Кроме того, пока вы пользуетесь Веб-сайтом, наши серверы ведут
                  журнал активности. В этом журнале хранится административная
                  информация и информация о трафике, включая IP-адрес источника,
                  время и дату доступа, посещенные веб-страницы, использование
                  языка, отчеты о сбоях программного обеспечения и тип
                  используемого браузера. Этот журнал предназначен только для
                  вас. Эта информация имеет решающее значение для предоставления
                  наших услуг, а также для их адекватности и функциональности.
                </p>
                <p className="policy__text-block-item">
                  Мы хотели бы сообщить вам, что в определенных случаях мы можем
                  провести исследование безопасности, чтобы подтвердить вашу
                  личность, возраст, а также другие предоставленные вами
                  регистрационные данные и проверить использование вами
                  Веб-сайта и ваши финансовые транзакции, чтобы убедиться в
                  отсутствии потенциального нарушения наших Условий и положений.
                  Условия и/или применимое законодательство.
                </p>
                <p className="policy__text-block-item">
                  С этой целью мы можем использовать вашу Личную информацию и
                  раскрывать вашу личную информацию третьим лицам для проверки
                  достоверности информации, которую вы предоставляете для
                  использования Веб-сайта, включая, возможно, но не обязательно,
                  международный обмен информацией. Резюме, которое будет
                  составлено на основе полученной проверки безопасности, может
                  включать запрос кредитного отчета и/или иную проверку
                  предоставленной вами информации на соответствие базам данных
                  третьих лиц. Личная информация также может быть получена из
                  списков клиентов, которые могут быть законно получены от
                  третьих лиц.
                </p>
              </div>
            </div>

            {/*  */}
            <div className="policy__text-block">
              <h4 className="policy__text-block-subitem">
                3. личная информация, которую мы собираем о вас
              </h4>
              <div className="policy__text-block-items">
                <p className="policy__text-block-item">
                  Благодаря обработке вашей личной информации, мы можем
                  выполнять наши обычные функции. Мы хотели бы проинформировать
                  вас о том, что мы используем вашу Личную информацию в
                  следующих целях:
                </p>
                <p className="policy__text-block-item">
                  3.1. С вашего согласия (и до его отзыва). Например, вы,
                  возможно, дали свое согласие на получение маркетинговых
                  информационных сообщений;
                </p>
                <p className="policy__text-block-item">
                  3.2. В необходимом объеме и для выполнения обязательств по
                  контракту, что подразумевает следующие действия:
                </p>
                <p className="policy__text-block-item">
                  3.2.1. обработку ваших онлайн-транзакций;
                </p>
                <p className="policy__text-block-item">
                  3.2.2. оказание поддержки клиентам.
                </p>
                <p className="policy__text-block-item">
                  3.3. Если это соответствует нашим законным деловым интересам.
                  что означает следующие действия:
                </p>
                <p className="policy__text-block-item">
                  3.3.1. Повышение качества и соответствия спектра услуг LTD Una
                  Planeta, а также улучшение качества обслуживания наших
                  клиентов;
                </p>
                <p className="policy__text-block-item">
                  3.3.2. Действия, направленные на удовлетворение определенных
                  требований бизнеса;
                </p>
                <p className="policy__text-block-item">
                  3.3.3. Действия, которые предпринимаются в целях
                  функционирования Веб-сайта;
                </p>
                <p className="policy__text-block-item">
                  3.3.4. Выполнение проверок действий по обеспечению
                  безопасности и идентификации;
                </p>
                <p className="policy__text-block-item">
                  3.3.5. Предоставление информации о наших промо-акциях, а также
                  информации, касающейся услуг LTD Una Planeta.
                </p>
                <p className="policy__text-block-item">
                  В случае упоминания наших законных интересов в обработке вашей
                  личной информации, пожалуйста, не стесняйтесь обращаться к
                  нам, чтобы уточнить информацию, которая относится к нашей
                  оценке воздействия на вас.
                </p>
              </div>
            </div>

            {/*  */}
            <div className="policy__text-block">
              <h4 className="policy__text-block-subitem">
                4. как долго мы храним вашу информацию
              </h4>
              <div className="policy__text-block-items">
                <p className="policy__text-block-item">
                  После закрытия вашей учетной записи личная информация о вашей
                  учетной записи будет храниться нами не более 1 года с даты ее
                  закрытия. Что касается финансовой информации, которая
                  собирается в рамках нашего сотрудничества, например, о выплате
                  комиссионных и т.д., то эта информация будет храниться около
                  10 лет после закрытия счета в соответствии с нашими
                  юридическими обязательствами в области мошенничества, борьбы с
                  отмыванием денег и налогообложения. Мы заверяем вас, что
                  информация хранится в надежном месте до истечения срока
                  хранения, после чего информация будет удалена и/или уничтожена
                  соответствующим образом.
                </p>
              </div>
            </div>

            {/*  */}
            <div className="policy__text-block">
              <h4 className="policy__text-block-subitem">4.1 ваши права</h4>
              <div className="policy__text-block-items">
                <p className="policy__text-block-item">
                  Пожалуйста, обратите внимание, что у вас есть права в
                  отношении вашей личной информации, которые включают в себя:
                </p>
                <p className="policy__text-block-item">
                  4.1.1. Право на получение информации о том, как мы используем
                  информацию, а также о ваших правах в этом отношении. Эта
                  информация должна предоставляться в ясной, прозрачной и
                  понятной форме. И для реализации этого вашего права
                </p>
                <p className="policy__text-block-item">
                  4.1.2. Право исправлять информацию о вас, которой мы
                  располагаем, в случае, если такая информация противоречит
                  действительности;
                </p>
                <p className="policy__text-block-item">
                  4.1.3. Право на доступ и изучение вашей личной информации,
                  которая находится в Нашем распоряжении;
                </p>
                <p className="policy__text-block-item">
                  4.1.4. Право удалять, ограничивать или "приостанавливать"
                  использование вашей личной информации (в исключительных
                  случаях);
                </p>
                <p className="policy__text-block-item">
                  4.1.5. Право запрашивать вашу личную информацию в цифровом
                  формате, включая, но не ограничиваясь, целью передачи такой
                  информации третьей стороне.
                </p>
                <p className="policy__text-block-item">
                  4.1.6. Право на возражение позволяет вам прекратить или
                  воспрепятствовать нам в обработке вашей личной информации в
                  отношении всей личной информации, которую мы храним о вас, или
                  только определенной информации. Это также может относиться
                  только к определенной цели, для которой мы обрабатываем
                  данные.
                </p>
                <p className="policy__text-block-item">
                  Ваши права в отношении автоматизированного принятия решений и
                  профилирования ограничивают нас в принятии исключительно
                  автоматизированных решений, в том числе основанных на
                  профилировании, которые имеют юридические или аналогичные
                  существенные последствия для физических лиц. Именно поэтому мы
                  будем информировать вас обо всех случаях, когда мы используем
                  такой подход, а именно в целях выявления случаев мошенничества
                  или уклонения от уплаты налогов.
                </p>
                <p className="policy__text-block-item">
                  В случае, если вы заинтересованы в реализации вышеуказанных
                  прав, пожалуйста, свяжитесь с нами по указанным ниже контактам
                  электронной почты: info@take-drop.net ваш запрос должен
                  содержать следующую информацию:
                </p>
                <p className="policy__text-block-item">4.1.1. Ваше имя;</p>
                <p className="policy__text-block-item">
                  4.1.2. Контактные данные;
                </p>
                <p className="policy__text-block-item">
                  4.1.3. Полная информация о вашем запросе.
                </p>
                <p className="policy__text-block-item">
                  Если вы не удовлетворены нашим ответом или считаете, что
                  использование нами вашей информации не соответствует закону о
                  защите данных, вы можете подать жалобу в соответствующий
                  регулирующий орган в пределах вашей юрисдикции в отношении
                  конфиденциальности данных.
                </p>
              </div>
            </div>

            {/*  */}
            <div className="policy__text-block">
              <h4 className="policy__text-block-subitem">
                5. Ваше согласие на обработку данных и его отзывов
              </h4>
              <div className="policy__text-block-items">
                <p className="policy__text-block-item">
                  В соответствии с GDPR, согласие должно быть дано в
                  положительной форме физическими лицами, которые предоставляют
                  свои персональные данные организациям. Согласно Европейской
                  директиве о защите данных, суть понятия "согласие" заключается
                  в следующем: "любое свободно данное конкретное и осознанное
                  указание на свои пожелания, посредством которого субъект
                  данных выражает свое согласие на обработку относящихся к нему
                  персональных данных".
                </p>
                <p className="policy__text-block-item">
                  После предоставления вашей личной информации на нашем
                  веб-сайте вы должны принять условия соглашения. В соответствии
                  с маркетинговой информацией и сообщениями вы оставляете за
                  собой право подписаться на получение таких сообщений.
                  Пожалуйста, обратите внимание, что вы можете в любое время
                  отказаться от рассылки маркетинговой информации, если вы не
                  заинтересованы в получении наших маркетинговых информационных
                  сообщений
                </p>
              </div>
            </div>

            {/*  */}
            <div className="policy__text-block">
              <h4 className="policy__text-block-subitem">6. безопасность</h4>
              <div className="policy__text-block-items">
                <p className="policy__text-block-item">
                  Пожалуйста, обратите внимание, что наша компания полностью
                  осознает важность информационной безопасности и методов,
                  необходимых для защиты информации. Мы храним всю личную
                  информацию, полученную от вас, в защищенной базе данных в
                  нашей защищенной сети и с помощью активного передового
                  программного обеспечения для обеспечения безопасности. Мы
                  принимаем меры для обеспечения надлежащего уровня безопасности
                  наших дочерних компаний, агентов, партнеров и поставщиков.
                </p>
              </div>
            </div>

            {/*  */}
            <div className="policy__text-block">
              <h4 className="policy__text-block-subitem">
                7.Автоматизированное принятие решений и профилирование
              </h4>
              <div className="policy__text-block-items">
                <p className="policy__text-block-item">
                  Пожалуйста, примите к сведению, что мы предпринимаем
                  многочисленные действия в целях соблюдения правил, касающихся
                  борьбы с отмыванием денег (AML), финансированием терроризма,
                  мошенничеством и другой незаконной деятельностью. Для этого мы
                  используем современное программное обеспечение, которое
                  отслеживает и информирует нас о подозрительных или
                  мошеннических транзакциях и действиях. В случае обнаружения
                  соответствующих транзакций мы передаем информацию об этом в
                  необходимые регулирующие и правоохранительные органы. Учетные
                  записи с подозрительной или мошеннической активностью могут
                  быть приостановлены и/или заблокированы нашей стороной.
                </p>
              </div>
            </div>

            {/*  */}
            <div className="policy__text-block">
              <h4 className="policy__text-block-subitem">
                8. международные переводы
              </h4>
              <div className="policy__text-block-items">
                <p className="policy__text-block-item">
                  Мы храним личную информацию на наших серверах в Грузии. Личная
                  информация, собранная на Веб-сайте, также может храниться и
                  обрабатываться в любой стране, в которой у нас или наших
                  партнеров, поставщиков или агентов есть оборудование. Мы
                  принимаем меры для обеспечения того, чтобы наши агенты,
                  партнеры и поставщики соблюдали наши стандарты
                  конфиденциальности, независимо от их местонахождения.
                </p>
              </div>
            </div>

            {/*  */}
            <div className="policy__text-block">
              <h4 className="policy__text-block-subitem">
                9. свяжитесь с нами и подайте жалобу
              </h4>
              <div className="policy__text-block-items">
                <p className="policy__text-block-item">
                  Пожалуйста, не стесняйтесь обращаться к нам, если у вас есть
                  какие-либо вопросы или жалобы в отношении данной Политики
                  конфиденциальности или того, как мы обрабатываем вашу личную
                  информацию, используя следующие данные:
                </p>
                <p className="policy__text-block-item">
                  9.1. адрес электронной почты: info@take-drop.net
                </p>
                <p className="policy__text-block-item">
                  9.2. адрес: Sakartvelo, K. Poti, Rustavelis Rkali 12, Bina 21,
                  Georgia.
                </p>
              </div>
            </div>

            {/*  */}
            <div className="policy__text-block">
              <h4 className="policy__text-block-subitem">
                10. изменения в настоящей политике конфиденциальности
              </h4>
              <div className="policy__text-block-items">
                <p className="policy__text-block-item">
                  Настоящим мы хотели бы сообщить вам, что мы постоянно
                  пересматриваем и обновляем настоящую Политику
                  конфиденциальности всякий раз, когда происходят существенные
                  изменения в том, как мы используем личную информацию, а также
                  для того, чтобы наша обработка информации соответствовала
                  действующему законодательству.
                </p>
                <p className="policy__text-block-item">
                  В случае внесения каких-либо изменений в Политику
                  конфиденциальности мы также можем связаться с вами, чтобы
                  уведомить вас о любых существенных изменениях, если сочтем их
                  важными и необходимыми. Настоящая Политика конфиденциальности
                  была первоначально разработана и опубликована в сентябре 2021
                  года.
                </p>
              </div>
            </div>

            {/*  */}
            <div className="policy__text-block">
              <h4 className="policy__text-block-subitem">
                11. изменения в настоящей политике конфиденциальности
              </h4>
              <div className="policy__text-block-items">
                <p className="policy__text-block-item">
                  Если вы хотите узнать больше об общих правилах защиты данных,
                  пожалуйста, перейдите по ссылке ниже:
                </p>
                <p className="policy__text-block-item">
                  <Link
                    to={
                      "https://ico.org.uk/for-organisations/data-protection-reform/overview-of-the-gdpr/"
                    }
                  >
                    https://ico.org.uk/for-organisations/data-protection-reform/overview-of-the-gdpr/
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

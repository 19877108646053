import React, { useState, useEffect } from "react";

import "./ScrollTop.scss";

export const ScrollTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 1000) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <button
        onClick={scrollToTop}
        className={`scroll-top_button ${isVisible ? "visible" : ""}`}
      >
        <svg
          width="24"
          height="14"
          viewBox="0 0 24 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9983 1.33168C11.5515 0.778437 12.4485 0.778437 13.0018 1.33168L22.9184 11.2483C23.4717 11.8016 23.4717 12.6986 22.9184 13.2518C22.3652 13.8051 21.4682 13.8051 20.915 13.2518L12 4.33688L3.08509 13.2518C2.53185 13.8051 1.63486 13.8051 1.08162 13.2518C0.528376 12.6986 0.528376 11.8016 1.08162 11.2483L10.9983 1.33168Z"
            fill="#c36113"
          />
        </svg>
      </button>
    </>
  );
};

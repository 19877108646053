import React, { useState, useEffect } from "react";
import { useGetCasesQuery } from "../../redux/cases/cases";
import { useSelector } from "react-redux";

export const TotalCases = () => {
    const { data: categoryCases, isSuccess } = useGetCasesQuery();
    const selectedCategory = useSelector(
      (state) => state.categorySlice.selectedCategory
    );
    const [totalCases, setTotalCases] = useState(0);
    useEffect(() => {
      if (isSuccess) {
        const categories = categoryCases?.data.cases;
        if (categories) {
          const categoriesWithAll = [...categories];
          setTotalCases(categoriesWithAll.length);
        }
      }
    }, [isSuccess, selectedCategory]);
    return (
        <>
        {totalCases}
        </>
    )
  }
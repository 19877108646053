import React, { useState } from "react";

import { Link } from "react-router-dom";

export const Item = ({
  rarity,
  image,
  caseImg,
  itemName,
  userImg,
  userName,
  user_id,
  case_url,
}) => {
  const [showCardInfo, setShowCardInfo] = useState(false);

  const handleMouseEnter = () => {
    setShowCardInfo(true);
  };

  const handleMouseLeave = () => {
    setShowCardInfo(false);
  };

  return (
    <div
      className="Drop"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`DropItem ${
          rarity === "Исключительный"
            ? "blue"
            : rarity === "Мастерский"
            ? " red"
            : rarity === "Заслуженный"
            ? "blue-army"
            : rarity === "Стандартное"
            ? "blue-army"
            : rarity === "Высшего класса"
            ? "blue"
            : rarity === "Примечательного типа"
            ? "blue-army"
            : rarity === "Экзотичного вида"
            ? " pink"
            : rarity === "Превосходный"
            ? "pink"
            : rarity === "Запрещённое"
            ? "purple"
            : rarity === "Промышленное качество"
            ? "blue"
            : rarity === "Тайное"
            ? "red"
            : rarity === "Ширпотреб"
            ? "green"
            : rarity === "Армейское качество"
            ? "blue-army"
            : rarity === "экстраординарного типа"
            ? "yellow"
            : rarity === "Контрабанда"
            ? "yellow"
            : rarity === "Засекреченное"
            ? "pink"
            : rarity === "Restricted"
            ? "purple"
            : rarity === "Industrial Grade"
            ? "blue"
            : rarity === "Covert"
            ? "red"
            : rarity === "Consumer Grade"
            ? "green"
            : rarity === "Mil-Spec"
            ? "blue-army"
            : rarity === "Extraordinary"
            ? "yellow"
            : rarity === "Contraband"
            ? "yellow"
            : rarity === "Classified"
            ? "pink"
            : rarity === "Remarkable"
            ? "blue"
            : rarity === "Masterpiece"
            ? " red"
            : rarity === "Distinguished"
            ? "blue-army"
            : rarity === "Standard"
            ? "blue-army"
            : rarity === "High Class"
            ? "blue"
            : rarity === "Exceptional"
            ? "blue-army"
            : rarity === "Exotic"
            ? " pink"
            : ""
        }`}
      >
        <div className="inner">
          <img src={image} alt="winning" />
        </div>
      </div>
      {showCardInfo && (
        <div className="DropInfo">
          <div className="DropInfo_wrapper">
            <div className="Inner">
              <div className="InfoCase">
                <Link to={`/case/${case_url}`}>
                  <img src={caseImg} alt={case_url} />
                </Link>
                <p>{itemName}</p>
              </div>
              <Link to={`/user/${user_id}`} className="InfoUser">
                <img src={userImg} alt="" />
                <p>{userName}</p>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

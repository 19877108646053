import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const soldItemsSlice = createSlice({
  name: "soldsItems",
  initialState,
  reducers: {
    setSoldsItems(state, action) {
      return action.payload;
    },
    removeFromSoldItems(state, action) {
      return state.filter((item) => item.id !== action.payload);
    },
  },
});

export const { setSoldsItems, removeFromSoldItems } = soldItemsSlice.actions;

export default soldItemsSlice.reducer;

import React from "react";
import link_img from "../../../img/link_img";

import "./ProfileInfo.scss";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const ProfileInfo = ({ userData }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    navigator.clipboard.writeText(userData?.data?.profile?.id);
  };

  return (
    <div className="ProfileInfo">
      <div className="ProfileInfoAvatar">
        <img src={userData?.data?.profile?.avatar_img} alt="" />
      </div>
      <div className="ProfileInfoUserName">
        <p className="userName">{userData?.data?.profile?.name}</p>
        <div className="userId">
          ID
          <span>#{userData?.data?.profile?.id}</span>
          <div className="userIdCopy" onClick={handleClick}>
            <img src={link_img.copy_userId} alt="" />
          </div>
        </div>
      </div>
      <div className="ProfileInfoLine Line1" aria-hidden="true"></div>
      <div className="ProfileInfoBlock">
        <div className="item countCase">
          <div className="icon">
            <img src={link_img.caseIco} alt="" />
          </div>
          <p>
            <span className="itemName">{t("cases")}</span>
            <span className="itemBottom itemCount">
              {userData?.data?.profile?.count_open_case}
            </span>
          </p>
        </div>
        <div className="ProfileInfoLine" aria-hidden="true"></div>
        <div className="item countCase">
          <div className="icon">
            <img src={link_img.user2} alt="" />
          </div>
          <p>
            <span className="itemName">{t("registered")}</span>
            <span className="itemBottom itemOnline">
              {userData?.data?.profile?.date_register}
            </span>
          </p>
        </div>
        <div className="ProfileInfoLine" aria-hidden="true"></div>
        <Link
          to={userData?.data?.profile?.steam_url}
          className="item countCase"
        >
          <div className="icon">
            <img src={link_img.steam} alt="" />
          </div>
          <p>
            <span className="itemName">{t("profileSteam")}</span>
            <span className="itemBottom itemProfile">Steam</span>
          </p>
        </Link>
      </div>
    </div>
  );
};

import React from "react";

import "./Notification.scss";

import { useTranslation } from "react-i18next";

export const Notification = ({
  price,
  showNotification,
  saleItems,
  isSaleItems,
  itemWithdrawal,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`notification ${showNotification ? "open" : ""}`}>
      <div className="notificationInner">
        <div className="notificationHead">
          {itemWithdrawal === `${t("tradeLinkNot")}`
            ? `${t("error")}`
            : `${t("ready")}`}
        </div>
        {itemWithdrawal !== "" ? (
          <p>{itemWithdrawal}</p>
        ) : isSaleItems ? (
          <p className="notificationText">{saleItems}</p>
        ) : (
          <p className="notificationText">
            {t("itemSold")} {price} ₽
          </p>
        )}
        <div className="notificationLine"></div>
      </div>
    </div>
  );
};

import React from "react";

export const UserItems = ({ rarity, image, name }) => {
  return (
    <div
      className={`ProfileBottomCard ${
        rarity === "Исключительный"
          ? "blue"
          : rarity === "Мастерский"
          ? " red"
          : rarity === "Заслуженный"
          ? "blue-army"
          : rarity === "Стандартное"
          ? "blue-army"
          : rarity === "Высшего класса"
          ? "blue"
          : rarity === "Примечательного типа"
          ? "blue-army"
          : rarity === "Экзотичного вида"
          ? " pink"
          : rarity === "Превосходный"
          ? "pink"
          : rarity === "Запрещённое"
          ? "purple"
          : rarity === "Промышленное качество"
          ? "blue"
          : rarity === "Тайное"
          ? "red"
          : rarity === "Ширпотреб"
          ? "green"
          : rarity === "Армейское качество"
          ? "blue-army"
          : rarity === "экстраординарного типа"
          ? "yellow"
          : rarity === "Контрабанда"
          ? "yellow"
          : rarity === "Засекреченное"
          ? "pink"
          : rarity === "Restricted"
          ? "purple"
          : rarity === "Industrial Grade"
          ? "blue"
          : rarity === "Covert"
          ? "red"
          : rarity === "Consumer Grade"
          ? "green"
          : rarity === "Mil-Spec"
          ? "blue-army"
          : rarity === "Extraordinary"
          ? "yellow"
          : rarity === "Contraband"
          ? "yellow"
          : rarity === "Classified"
          ? "pink"
          : rarity === "Remarkable"
          ? "blue"
          : rarity === "Masterpiece"
          ? " red"
          : rarity === "Distinguished"
          ? "blue-army"
          : rarity === "Standard"
          ? "blue-army"
          : rarity === "High Class"
          ? "blue"
          : rarity === "Exceptional"
          ? "blue-army"
          : rarity === "Exotic"
          ? " pink"
          : ""
      }`}
    >
      <div
        className={`ProfileCardInner ${
          rarity === "Исключительный"
            ? "blue"
            : rarity === "Мастерский"
            ? " red"
            : rarity === "Заслуженный"
            ? "blue-army"
            : rarity === "Стандартное"
            ? "blue-army"
            : rarity === "Высшего класса"
            ? "blue"
            : rarity === "Примечательного типа"
            ? "blue-army"
            : rarity === "Экзотичного вида"
            ? " pink"
            : rarity === "Превосходный"
            ? "pink"
            : rarity === "Запрещённое"
            ? "purple"
            : rarity === "Промышленное качество"
            ? "blue"
            : rarity === "Тайное"
            ? "red"
            : rarity === "Ширпотреб"
            ? "green"
            : rarity === "Армейское качество"
            ? "blue-army"
            : rarity === "экстраординарного типа"
            ? "yellow"
            : rarity === "Контрабанда"
            ? "yellow"
            : rarity === "Засекреченное"
            ? "pink"
            : rarity === "Restricted"
            ? "purple"
            : rarity === "Industrial Grade"
            ? "blue"
            : rarity === "Covert"
            ? "red"
            : rarity === "Consumer Grade"
            ? "green"
            : rarity === "Mil-Spec"
            ? "blue-army"
            : rarity === "Extraordinary"
            ? "yellow"
            : rarity === "Contraband"
            ? "yellow"
            : rarity === "Classified"
            ? "pink"
            : rarity === "Remarkable"
            ? "blue"
            : rarity === "Masterpiece"
            ? " red"
            : rarity === "Distinguished"
            ? "blue-army"
            : rarity === "Standard"
            ? "blue-army"
            : rarity === "High Class"
            ? "blue"
            : rarity === "Exceptional"
            ? "blue-army"
            : rarity === "Exotic"
            ? " pink"
            : ""
        }`}
      >
        <div
          className="ProfileBottomBlockRing"
          style={{
            border: `2px solid ${
              rarity === "Исключительный"
                ? "#00A3FF"
                : rarity === "Мастерский"
                ? "#B12E2E"
                : rarity === "Заслуженный"
                ? "#0033B7"
                : rarity === "Стандартное"
                ? "#0033B7"
                : rarity === "Высшего класса"
                ? "#00A3FF"
                : rarity === "Примечательного типа"
                ? "#0033b7"
                : rarity === "Примечательного типа"
                ? ""
                : rarity === "Экзотичного вида"
                ? "#FF00BC"
                : rarity === "Превосходный"
                ? "#ff00bc"
                : rarity === "Запрещённое"
                ? "#7B02DC"
                : rarity === "Промышленное качество"
                ? "#00A3FF"
                : rarity === "Тайное"
                ? "#B12E2E"
                : rarity === "Ширпотреб"
                ? "#8FA6B9"
                : rarity === "Армейское качество"
                ? "#0033B7"
                : rarity === "экстраординарного типа"
                ? "#EFCC17"
                : rarity === "Контрабанда"
                ? "#EFCC17"
                : rarity === "Засекреченное"
                ? "#FF00BC"
                : rarity === "Restricted"
                ? "#7B02DC"
                : rarity === "Industrial Grade"
                ? "#00A3FF"
                : rarity === "Covert"
                ? "#B12E2E"
                : rarity === "Consumer Grade"
                ? "#8FA6B9"
                : rarity === "Mil-Spec"
                ? "#0033B7"
                : rarity === "Extraordinary"
                ? "#EFCC17"
                : rarity === "Contraband"
                ? "#EFCC17"
                : rarity === "Classified"
                ? "#FF00BC"
                : rarity === "Remarkable"
                ? "#00A3FF"
                : rarity === "Masterpiece"
                ? "#B12E2E"
                : rarity === "Distinguished"
                ? "#0033B7"
                : rarity === "Standard"
                ? "#0033B7"
                : rarity === "High Class"
                ? "#00A3FF"
                : rarity === "Exceptional"
                ? "#0033b7"
                : rarity === "Exotic"
                ? "#FF00BC"
                : ""
            }`,
          }}
        >
          <img src={image} alt="" />
        </div>
        <div className="ProfileBottomBlockDescription">
          <p className="name">{name}</p>
          <span
            className="categoryName"
            style={{
              color: `${
                rarity === "Исключительный"
                  ? "#33687e"
                  : rarity === "Мастерский"
                  ? " #7f3535"
                  : rarity === "Заслуженный"
                  ? "#33687e"
                  : rarity === "Стандартное"
                  ? "#33687e"
                  : rarity === "Высшего класса"
                  ? "#33687e"
                  : rarity === "Примечательного типа"
                  ? "#336872"
                  : rarity === "Экзотичного вида"
                  ? " #7f357b"
                  : rarity === "Превосходный"
                  ? "#7f357b"
                  : rarity === "Запрещённое"
                  ? "#7257a8"
                  : rarity === "Промышленное качество"
                  ? "#33687e"
                  : rarity === "Тайное"
                  ? "#7f3535"
                  : rarity === "Ширпотреб"
                  ? "#8FA6B9"
                  : rarity === "Армейское качество"
                  ? "#33687e"
                  : rarity === "экстраординарного типа"
                  ? "#7c842d"
                  : rarity === "Контрабанда"
                  ? "#7c842d"
                  : rarity === "Засекреченное"
                  ? "#7f357b"
                  : //
                  rarity === "Restricted"
                  ? "#7257a8"
                  : rarity === "Industrial Grade"
                  ? "#33687e"
                  : rarity === "Covert"
                  ? "#7f3535"
                  : rarity === "Consumer Grade"
                  ? "#8FA6B9"
                  : rarity === "Mil-Spec"
                  ? "#33687e"
                  : rarity === "Extraordinary"
                  ? "#7c842d"
                  : rarity === "Contraband"
                  ? "#7c842d"
                  : rarity === "Classified"
                  ? "#7f357b"
                  : rarity === "Remarkable"
                  ? "#33687e"
                  : rarity === "Masterpiece"
                  ? " #7f3535"
                  : rarity === "Distinguished"
                  ? "#33687e"
                  : rarity === "Standard"
                  ? "#33687e"
                  : rarity === "High Class"
                  ? "#33687e"
                  : rarity === "Exceptional"
                  ? "#336872"
                  : rarity === "Exotic"
                  ? " #7f357b"
                  : rarity === "Exotic"
                  ? "#7f357b"
                  : ""
              }`,
            }}
          >
            {rarity}
          </span>
        </div>
      </div>
    </div>
  );
};

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: localStorage.getItem("i18nextLng") || "ru",
    returnObjects: true,
    resources: {
      ru: {
        translation: {
          insufficientFunds: "Недостаточно средств",
          replenishBalance: "Пополнение баланса",
          notAuthorized: "Вы не авторизованы",
          authorize: "Авторизоваться",
          skinSale: "Подтверждение продажи скинов",
          ready: "Готово",
          error: "Ошибка",
          tradeLinkNot: "Ссылка на трейд не установлена",
          sellThisItem: "Вы действительно хотите продать этот предмет за",
          cancel: "Отмена",
          sellAllItems: "Вы действительно хотите продать все предметы?",
          confirmationSkinWithdrawal: "Подтверждение вывода скинов",
          sureItem: "Вы действительно хотите вывести данный предмет?",
          itemSold: " Предмет успешно продан за",
          soldFor: "Продан за",
          site: "на сайте Steam",
          inputTrade: "Введите свою trade ссылку",
          noItems: " У вас нет предметов с ценой от",
          noUserItems: "У данного пользователя нет предметов с ценой от ",
          noUserItemsTo: "до",
          to: "",
          from: "до",
          save: "Сохранить",
          skinWithdrawal: "Вывод скинов",
          unavailable: "недоступен",
          available: "доступен",
          linkIncorrect: "Ссылка на трейд неверная",
          linkSuccessfully: "Ссылка на трейд успешно обновлена!",
          withdraw: "Вывести",
          cases: "Кейсы",
          registered: "На сайте",
          login: "войти",
          replenish: "пополнить",
          profileSteam: "Профиль в",
          linkTrade: "Показать ссылку на трейд",
          hideLinkTrade: "Скрыть ссылку на трейд",
          linkSearch: "Найти ссылку можно",
          toHome: "Вернуться на главную",
          logout: "Выйти из аккаунта",
          sellAll: "Продать все",
          seeAll: "Посмотерть все",
          openCase: "Открыть кейс",
          fastOpen: "Быстрое открытие",
          contentCases: "Содержимое кейса",
          home: "Главная",
          tryAgain: "Попробовать ещё",
          sellFor: "Продать за",
          sell: "продать",
          about: "О сайте",
          termsUse: "Пользовательское соглашение",
          faq: "FAQ",
          termsConditions: "Условия и сроки",
          privacyPolicy: "Политика конфиденциальности",
          contact: "Контакты",
          reviews: "Отзывы",
          shopCases: "Магазин кейсов",
          footerDescriptionOne:
            "На нашем сайте вы можете открывать различные кейсы и покупать готовые скины за бонусные Take-Drop баллы!",
          footerDescriptionTwo:
            "Все обмены проходят автоматически через площадку",

          allRights: "Все права пренадлежат Una Planeta ltd",
          through: "через",
          loading: "Загрузка",
          required: "обязательно к заполнению",
          minAmount: "Минимальная сумма ",
          replenishment: "Пополнение баланса",
          paymentMethod: "Выберите способ оплаты",
          amount: "Сумма",
          min: "Мин",
          upBalance: "пополнить счет",
          maxAmount: "Максимальная сумма",
          work_name: "Ведутся технические работы",
          participate: "участвовать",
          participation: "Условия участия:",
          replenished: "Пополнено",
          days: "Дней",
          hour: "Час",
          minutes: "Мин",
          sec: "Сек",
          winners: "Победители",
        },
      },
      en: {
        translation: {
          insufficientFunds: "Insufficient funds",
          replenishBalance: "Replenish balance",
          notAuthorized: "You are not authorized",
          authorize: "Authorize",
          skinSale: "Confirmation of skin sale",
          ready: "Ready",
          error: "Error",
          tradeLinkNot: "Trade link not set",
          sellThisItem: "Are you sure you want to sell this item for",
          cancel: "Cancel",
          sellAllItems: "Are you sure you want to sell all items?",
          confirmationSkinWithdrawal: "Confirmation of skin withdrawal",
          sureItem: "Are you sure you want to withdraw this item?",
          itemSold: " Item successfully sold for",
          site: "Steam website",
          inputTrade: "Enter your trade link.",
          noItems: "You do not have any items with a",
          noUserItems:
            "This user does not have any items with a price range from ",
          noUserItemsTo: "to",
          to: "to",
          from: "from",
          save: "Save",
          skinWithdrawal: "Skin withdrawal",
          unavailable: "unavailable",
          available: "available",
          linkIncorrect: "Trade link is incorrect",
          linkSuccessfully: "Trade link successfully updated!",
          withdraw: "Withdraw",
          cases: "Cases",
          registered: "Registered",
          login: "log in",
          replenish: "replenish",
          profileSteam: "Profile in",
          linkTrade: "Show trade link",
          hideLinkTrade: "Hide trade link",
          linkSearch: "You can find a link on the",
          toHome: "Back to home",
          logout: "Log out",
          sellAll: "Sell all",
          seeAll: "See all",
          openCase: "Open case",
          fastOpen: "Fast open",
          contentCases: "Case contents",
          home: "Home",
          tryAgain: "Try again",
          sellFor: "Sell for",
          soldFor: "Sold for",
          sell: "sell",
          about: "About",
          termsUse: "Terms of use",
          faq: "FAQ",
          termsConditions: "Terms and conditions",
          privacyPolicy: "Privacy policy",
          contact: "Contact",
          reviews: "Reviews",
          shopCases: "Case shop",
          footerDescriptionOne:
            "On our website you can open various cases and buy ready-made skins for bonus Take-Drop points!",
          footerDescriptionTwo:
            "All exchanges are carried out automatically through the platform",

          allRights: "All rights belong to Una Planeta ltd",
          through: "through",
          loading: "Loading",
          required: "required",
          minAmount: "Minimum amount ",
          replenishment: "Balance replenishment",
          paymentMethod: "Choose payment method",
          amount: "Amount",
          min: "Min",
          upBalance: "Top up balance",
          maxAmount: "maximum amount",
          work_name: "The technical work is in progress",
          participate: "participate",
          participation: "The terms of participation:",
          replenished: "Replenished",
          days: "Days",
          hour: "Hour",
          minutes: "Min",
          sec: "Sec",
          winners: "Winners",
        },
      },
    },
  });
